@import '../../../app/styles/variables/mixins';
@import '../../../app/styles/variables/variables';

.clientCard_background {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: $themeColorGray;
  border-radius: $largeBorderRadius;
  padding: $defaultLargeMargin;
  @include hoverCursorPointer;

  h3 {
    text-align: center;
    margin: 0 0 5px 0;
  }

  h3:hover {
    border-radius: 10px;
    border: black solid 1px;
  }

  .fio {
    max-width: 330px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  p {
    margin: 7px 0;

    span {
      text-decoration: underline;
    }
  }
}
