@import '../../../app/styles/variables/mixins';
@import '../../../app/styles/variables/variables';

.arrivalOfProducts_mainBlock {
  @include wrapperMainBlock;
  @include flexOnlyBetween;
  gap: $defaultLargeMargin;
  height: 100%;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }

  .arrivalOfProducts_leftSide {
    background-color: $themeColorDark;
    border-radius: $largeBorderRadius;
    padding: $defaultLargeMargin;
    @include flexColumn;
    @include divExtraLargeMarginBottom;
    width: 30%;
    height: 90%;

    @media screen and (max-width: 768px) {
      width: 100%;
    }

    > div {
      //border: 1px solid white;
      width: 100%;
    }

    .leftSide_title {
      text-align: center;
      font-size: 20px;
    }

    .leftSide_uploadFile {
      @include inputTypeFile($themeColorMilk);
    }

    .leftSide_info {
      @include flexCenter;
      padding: 10px;
    }

    .button_chooseItem {
      button {
        width: 100%;
        padding: 10px;
      }
    }

    .leftSide_delivery {
      @include flexBetween;
      gap: $defaultLargeMargin;

      > div {
        @include defaultFiledStyle;
        width: 100%;
      }
    }

    .leftSide_search {

    }

    .history {
      background-color: $themeColorGray;
      border-radius: $smallBorderRadius;
      padding: $defaultLargeMargin;
      height: 250px;
      overflow: auto;

      .hist_item {
        margin-bottom: 5px;
        padding: 5px;
        border-radius: $smallBorderRadius;
        background-color: $themeColorMilk;
        color: black;
      }
    }

    .leftSide_metrika {
      background-color: $themeColorGray;
      border-radius: $smallBorderRadius;
      padding: $defaultLargeMargin;
      @include flexColumn;
      @include divSmallMarginBottom;

      .metrika_title {
        font-weight: bold;
        text-decoration: underline;
      }
    }

    .leftSide_footerButtons {
      @include flexOnlyBetween;
      gap: $defaultLargeMargin;

      > div {
        width: 100%;

        button {
          width: 100%;
          padding: $smallButtonPadding;
        }
      }
    }
  }

  // стили для прихода товаров
  .arrivalOfProducts_rightSide {
    width: 70%;
    background-color: $themeColorDark;
    border-radius: $largeBorderRadius;
    padding: $defaultLargeMargin;

    @media screen and (max-width: 768px) {
      width: 100%;
      min-height: 500px;
    }
  }

  // стили для инвентаризации товаров
  .inventoryOfProducts_rightSide {
    width: 70%;
    background-color: $themeColorDark;
    border-radius: $largeBorderRadius;
    padding: $defaultLargeMargin;
    @include flexColumn;
    gap: $defaultLargeMargin;

    > div {
      width: 100%;
      height: 100%;
      background-color: $themeColorDark;
      border-radius: $largeBorderRadius;
      padding: $defaultLargeMargin;
    }
  }
}