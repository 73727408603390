@import '../../app/styles/variables/mixins';
@import '../../app/styles/variables/variables';

.leftSide_burgerMenu {
    position: relative;

    .burgerMenu_iconButton {
        @include hoverCursorPointer;
        font-weight: bold;
        font-size: 20px;
        user-select: none;
    }
    .burgerMenu_menuList {
        position: absolute;
        top: 25px;
        right: -25px;
        background-color: var(--header-color);
        //border-radius: $defaultSmallMargin;
        border: 1px solid white;
        padding: $defaultLargeMargin;
        min-width: 130px;
        z-index: 100;

        .menuList_item {
            padding: 3px 0;
            &:hover {
                cursor: pointer;
                background-color: $themeColorGray;
            }
        }
    }
}