// colors
$themeColorWhite: white;
$themeColorSomeWhite: #f8f8f8;
$themeColorMilk: #c9c9c9;
$themeColorGray: #555f69;
$themeColorDark: #33373B;
$themeColorBlack: black;

$headerColor: #1876D1;
$headerColorHover: #1c508a;

$themeShopColorGray: #D9D9D9;
$themeShopColorDark: #2C2D2E;

// containers
$containerWidth: 1400px;
$containerShopPadding: 20px 60px;

// border-radius
$extraSmallBorderRadius: 5px;
$smallBorderRadius: 10px;
$largeBorderRadius: 20px;

// default margins and paddings
$defaultExtraSmallMargin: 5px;
$defaultSmallMargin: 10px;
$defaultLargeMargin: 20px;
$defaultExtraLargeMargin: 40px;

// buttons
$smallButtonPadding: 10px 20px;
$largeButtonPadding: 15px 20px;
$smallButtonHeight: 40px;
$largeButtonHeight: 50px;

$divButtonPadding: 5px 10px;
$bgColorButtonConfirm: #BF3B38;

$bgColorProfileStatusWaitingPayment: #ffc259;
$bgColorProfileStatusWaitingPackage: #FFA900;
$bgColorProfileStatusReady: green;
$bgColorProfileStatusCanceled: red;

$filterButtonWaitingColor: red;
$filterButtonWaitingColorExtra: #ff9245;
$filterButtonInProcessColor: #ff6a00;
$filterButtonReadyColor: green;

// text
$minLineHeight: 1.2;
