@import '../../../app/styles/variables/mixins';
@import '../../../app/styles/variables/variables';

.createWorkModal_mainBox {
    @include defaultModalStyle;

    .createWorkModal_inputs {
        margin-bottom: $defaultLargeMargin;
        @include divSmallMarginBottom;
    }
    .createWorkModal_buttons {
        @include workAndProductModalsButtonBlock;
    }
}