@import '../../../app/styles/variables/mixins';
@import '../../../app/styles/variables/variables';

.shopStorageModal_mainBlock {
  @include defaultModalStyle;
  @include flexOnlyBetween;
  gap: $defaultLargeMargin;
  min-width: 700px;

  @media screen and (max-width: 768px) {
    width: 93vw;
    align-items: center;
    justify-content: center;
    align-items: self-start;
    min-width: 93vw;
  }
  
  @media screen and (max-width: 575px) {
    flex-direction: column;
    align-items: center;
    max-height: 550px;
    overflow-y: scroll;
    padding-top: 350px;
  }

  @include createShopStorageModal(400px, 50%, 50%);
}

