@import '../../../app/styles/variables/mixins';
@import '../../../app/styles/variables/variables';

.checkArchiveModal_mainBox {
    @include defaultModalStyle;
    @include flexColumn;
    width: 550px;

    @media screen and (max-width: 768px) {
        width: 75vw;
        max-height: 550px;
        overflow-y: scroll;
    }

    @media screen and (max-width: 440px) {
        width: 90vw;
    }

    .checkArchiveModal_title {
        @include modalTitle;
    }

    .checkArchiveModal_list {
        @include divLargeMarginBottom;
        max-height: 550px;
        overflow: auto;
        padding-right: 10px;

        .checkArchiveModal_item {
            @include flexOnlyBetween;
            background-color: $themeColorGray;
            border: 1px solid $themeColorMilk;
            border-radius: $smallBorderRadius;
            padding: $defaultSmallMargin;
            @include hoverCursorPointer;

            @media screen and (max-width: 768px) {
                width: 65vw;
                font-size: 2.2vw;
            }

            @media screen and (max-width: 440px) {
                width: 77vw;
                font-size: 2.6vw;
            }

            .item_content {
                @include flexBetween;

                @media screen and (max-width: 640px) {
                    flex-direction: column;
                    width: 100%;
                    padding: 0 10px;
                }

                .content_info {
                    height: 100%;
                    //width: 200px;
                    font-size: 20px;
                    border-right: 2px solid $themeColorMilk;
                    padding-right: 10px;
                    @include flexColumnOnly;
                    justify-content: space-between;
                    align-items: flex-start;
                    @include divSmallMarginBottom;

                    @media screen and (max-width: 768px) {
                        font-size: 2.8vw;
                    }

                    @media screen and (max-width: 640px) {
                        border-right: none;
                        border-bottom: 1px solid $themeColorMilk;
                        padding-right: 0;
                        padding-bottom: 0;
                        margin-bottom: 10px;
                        flex-direction: row;
                        width: 100%;
                    }

                    .cashBlock {
                        @include flexCenter;

                        .cashBlock_info {
                            padding-left: 10px;
                            font-size: 20px;
                        }
                    }
                }
                .content_cash {
                    display: flex;
                    justify-content: space-around !important;
                }

                @include modalDateBlock;
            }
        }
    }
}