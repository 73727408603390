.wrapper {
  height: 100vh;

  .control {
    display: flex;
    gap: 10px;
    justify-content: space-between;
  }

  .data {

  }
}