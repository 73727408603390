@import '../../../app/styles/variables/mixins';
@import '../../../app/styles/variables/variables';


.mainPageMainBlock {
  @include wrapperMainBlock;


  .mainPage_header {
    display: flex;
    margin-bottom: $defaultLargeMargin;
    gap: $defaultLargeMargin;

    @media screen and (max-width: 440px) {
      width: 100%;
      flex-direction: column;
    }

    .mainPage_header_leftSide {
      display: flex;
      flex-direction: column;
      width: 70%;

      @media screen and (max-width: 440px) {
        width: 100%;
      }

      .header_leftSide_deal {
        // @include flexOnlyBetween;
        flex-wrap: wrap;
        border-radius: $largeBorderRadius;
        background-color: $themeColorDark;
        margin-bottom: $defaultLargeMargin;
        padding: $defaultLargeMargin;

        display: flex;
        justify-content: start;
        column-gap: 3vw;
        row-gap: 1.4vw;

        @media screen and (max-width: 1024px) {
          justify-content: center;
        }

        button {
          padding: 5px 10px;
          height: 40px;

          @media screen and (max-width: 440px) {
            font-size: 3vw;
          }
        }
      }

      .header_leftSide_info {
        // @include flexOnlyBetween;
        flex-wrap: wrap;
        border-radius: $largeBorderRadius;
        background-color: $themeColorDark;
        padding: $defaultLargeMargin;

        display: flex;
        justify-content: start;
        column-gap: 3vw;
        row-gap: 1.4vw;

        @media screen and (max-width: 1024px) {
          justify-content: center;
        }

        button {
          @include flexCenter;
          padding: 5px 10px;
          height: 40px;

          @media screen and (max-width: 440px) {
            font-size: 3vw;
          }
        }
      }
    }

    .mainPage_header_rightSide {
      @include flexCenter;
      background-color: $themeColorDark;
      border-radius: $largeBorderRadius;
      padding: $defaultLargeMargin;
      width: 30%;
      text-align: center;

      @media screen and (max-width: 440px) {
        width: 100%;
      }
    }
  }


  .mainPage_content {
    display: flex;
    gap: $defaultLargeMargin;

    @media screen and (max-width: 1024px) {
      flex-direction: column-reverse;
    }

    .content_leftSide {
      background-color: $themeColorDark;
      border-radius: $largeBorderRadius;
      width: 50%;

      @media screen and (max-width: 1024px) {
        width: 100%;
      }

      @media screen and (max-width: 1024px) {
        width: 100%;
      }

      .leftSide_title {
        @include flexCenter;
        margin-top: $defaultSmallMargin;

        @media screen and (max-width: 1024px) {
          margin-top: 2vw;
          font-size: 3vw;
        }

        @media screen and (max-width: 440px) {
          margin-top: 4.5vw;
          font-size: 4vw;
        }
      }

      .leftSide_tasks {
        display: grid;
        gap: 2vw;
        grid-template-columns: 1fr 1fr;
        justify-content: center;
        flex-wrap: wrap;
        background-color: $themeColorGray;
        margin: $defaultLargeMargin;
        padding: 1.8vw;
        border-radius: $largeBorderRadius;

        @media screen and (max-width: 440px) {
          grid-template-columns: 1fr;
          padding: 6vw;
        }

        .tasks_taskItem {
          background-color: $themeColorWhite;
          border-radius: $smallBorderRadius;
          padding: $defaultSmallMargin;
          margin: $defaultSmallMargin;
          color: black;
          width: 100%;
          height: 100px;
          margin: auto;

          @media screen and (max-width: 1024px) {
            height: 15vw;
            font-size: 2vw;
          }

          @media screen and (max-width: 440px) {
            height: 45vw;
          }
        }
      }
    }


    .content_rightSide {
      width: 50%;
      @include flexColumn;

      @media screen and (max-width: 1024px) {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 2vw;
      }

      @media screen and (max-width: 768px) {
        flex-direction: column-reverse;
      }

      .rightSide_top {
        @include flexColumn;
        justify-content: space-between;
        background-color: $themeColorDark;
        border-radius: $largeBorderRadius;
        padding: $defaultLargeMargin;
        margin-bottom: 10px;
        height: 60%;
        width: 100%;

        @media screen and (max-width: 1024px) {
          height: 25vw;
          margin: 0;
        }

        @media screen and (max-width: 768px) {
          height: 45vw;
        }

        @media screen and (max-width: 440px) {
          height: 70vw;
        }

        button {
          &:nth-child(2) {
            width: 4vw;

            @media screen and (max-width: 768px) {
              width: 8vw;
            }

            @media screen and (max-width: 768px) {
              width: 11vw;
            }
          }
        }

        .select {
          width: 100%;
        }

        .rightSide_top_search {
          @include flexOnlyBetween;
          gap: $defaultLargeMargin;
          width: 100%;

          @media screen and (max-width: 1024px) {
            margin: 1vw 0 0 0;
          }

          .search_chooseClientButton {
            button {
              width: 200px;
              height: $largeButtonHeight;
            }
          }

          .search_searchInput {
            @include flexCenter;
            width: 100%;
            height: 30px;
            padding: $defaultSmallMargin;
            //margin-left: $defaultLargeMargin;
            border: 2px solid $themeColorMilk;
            border-radius: $smallBorderRadius;

            @media screen and (max-width: 768px) {
              padding: 3vw 0;
            }
          }

          .search_searchInput:hover {
            background-color: #4b4b4b;
          }
        }


        .rightSide_top_info {
          height: 50%;
          width: 100%;
          padding: $defaultSmallMargin;
          background-color: $themeColorGray;
          border-radius: $largeBorderRadius;
          overflow: auto;

          @media screen and (max-width: 1024px) {
            height: 40%;
            margin: 1vw 0;
          }

          .cashbox_table_wrapper {
            display: flex;
            align-items: center;
            //margin-top: 5px;
            //margin-left: 10px;
            margin-bottom: 5px;
            //padding: 5px;
            //width: 97%;

            .cashbox_table_left {
              display: flex;
              border: 2px solid white;
              border-radius: 10px;
              padding: 5px;
              width: 100%;
              margin-right: 5px;

              .cashbox_table_name {
                border-radius: 5px;
                width: 90%;
                margin-right: 5px;
              }

              .cashbox_table_price {
                @include flexCenter;
                background: white;
                color: black;
                border-radius: 5px;
                padding: 5px;
                margin-right: 5px;
              }

              .cashbox_table_total {
                @include flexCenter;
                background: white;
                color: black;
                border-radius: 5px;
                padding: 5px;
              }
            }

            .cashbox_table_remove {
              display: flex;
              justify-content: space-around;
              border: 2px solid darkred;
              border-radius: 5px;
              padding: 5px;
              width: 50px;
              height: 50px;
            }

            .cashbox_table_remove:hover {
              color: red;
              border: 2px solid red;
            }
          }


        }


        .rightSide_top_result {
          @include flexCenter;
          justify-content: space-between;
          width: 100%;
          gap: 3vw;

          .result_chooseCashboxBtn {
            button {
              height: $largeButtonHeight;
              width: 200px;
            }
          }

          .result_cancelBtn {
            button {
              width: 50px;
              height: 50px;
              padding: 5px;
              font-size: 20px;
            }
          }

          .result_span {
            @include flexCenter;
            background-color: $themeColorGray;
            border-radius: $smallBorderRadius;
            padding: $largeButtonPadding;
            height: $largeButtonHeight;
            width: 200px;

            @media screen and (max-width: 1024px) {
              width: 6vw;
            }

            @media screen and (max-width: 768px) {
              width: 10vw;
            }
          }

          .result_payBtn {
            button {
              height: $largeButtonHeight;
              width: 150px;
            }
          }
        }
      }

      .rightSide_bottom {
        @include flexOnlyBetween;
        background-color: $themeColorDark;
        border-radius: $largeBorderRadius;
        padding: $defaultLargeMargin;
        width: 100%;
        height: 60%;
        margin-top: 10px;

        @media screen and (max-width: 1024px) {
          height: 25vw;
          margin-top: 0;
        }

        @media (max-width: 768px) {
          height: 42vw;
        }

        @media (max-width: 440px) {
          flex-direction: column;
          height: 88vw;
        }

        .bottom_left {
          @include flexColumn;
          justify-content: center;
          background-color: $themeColorGray;
          border-radius: $smallBorderRadius;
          margin-right: $defaultSmallMargin;
          font-size: 20px;
          width: 60%;

          @media screen and (max-width: 440px) {
            width: 100%;
            height: 20vw;
          }
        }

        .bottom_right {
          @include flexColumn;
          justify-content: space-between;
          width: 40%;

          button {
            @media screen and (max-width: 1280px) {
              height: 55px !important;
            }

            @media screen and (max-width: 640px) {
              height: 40px !important;
            }
          }

          > div {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 0.6vw;

            @media screen and (max-width: 440px) {
              gap: 2.2vw
            }
          }

          @media screen and (max-width: 440px) {
            width: 100%;
            gap: 2vw;
          }

          .shiftStatus_open {
            @include shiftStatus(green, white);
          }

          .shiftStatus_pause {
            @include shiftStatus(orange, black);
          }

          .shiftStatus_closed {
            @include shiftStatus(red, white);

            @media screen and (max-width: 440px) {
              height: 12vw;
              display: flex;
              justify-content: center;
            }
          }

          .shiftTime {
            @include flexCenter;
            gap: 5px;

            @media screen and (max-width: 1024px) {
              padding: .8vw 0;
              font-size: 1.2vw;
              flex-direction: row;
            }

            @media screen and (max-width: 768px) {
              font-size: 2.2vw;
            }

            @media screen and (max-width: 440px) {
              font-size: 3.2vw;
            }
          }

          .shiftTiming {
            @include flexBetween;
            flex-direction: row;
            font-size: 14px;
            text-align: center;
            background-color: $themeColorGray;
            border-radius: $smallBorderRadius;
            padding: $defaultSmallMargin;

            @media screen and (max-width: 1024px) {
              font-size: 0.8vw;
              text-align: center;
            }

            @media screen and (max-width: 768px) {
              font-size: 1.8vw;
              text-align: center;
            }

            @media screen and (max-width: 440px) {
              font-size: 3.2vw;
              justify-content: center;
              gap: 13vw;
            }
          }

          .buttons {
            button {
              height: $smallButtonHeight;
              width: 100%;

              @media screen and (max-width: 1024px) {
                font-size: 1vw;
                height: 3vw;
              }

              @media screen and (max-width: 768px) {
                font-size: 2vw;
                height: 5vw;
              }

              @media screen and (max-width: 440px) {
                height: 10vw;
                font-size: 3.4vw;
              }
            }

            .pauseWorkDay_button {
              margin-bottom: $defaultSmallMargin;
            }

            .endWorkDay_button {
              button {
                &:hover {
                  background-color: red;
                }
              }
            }
          }
        }
      }
    }
  }
}

.deleteBox {
  img {
    @media screen and (max-width: 768px) {
      height: 100%;
      object-fit: cover;
      width: 6vw;
    }
  }
}
