@import "reset";
@import "./variables/global";
@import "./variables/variables";
@import "./themes/normal";
@import "./themes/dark";

.app {
  overflow: auto;
  background-color: #7b8895;
  color: white;
}

// content WorkspaceHeaderProvider
.content {
  max-width: 1400px;
  margin: 0 auto;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

// Стили для внутренних scrollbar
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--primary-color, #414141);
  border-radius: 2px;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--inverted-primary-color, #fff);
  border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: grey;
  opacity: 0.5;
}

.app::-webkit-scrollbar {
  border-radius: 2px;
  width: 10px;
  height: 10px;
}

/* Track */
.app::-webkit-scrollbar-track {
  background: var(--primary-color);
  border-radius: 2px;
}

/* Handle */
.app::-webkit-scrollbar-thumb {
  border-radius: 2px;

  background: var(--inverted-primary-color);
}

/* Handle on hover */
.app::-webkit-scrollbar-thumb:hover {
  border: 1.5px solid #627bff;
}
