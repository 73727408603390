@import './src/app/styles/variables/mixins';
@import './src/app/styles/variables/variables';

.tableBox {
    //border: 1px solid white;
    @include widthHeightFull;
    height: 100%;

    .tableBox_buttons {
        @include flexBetween;
        margin-bottom: $defaultSmallMargin;
        gap: 2vw;

        @media screen and (max-width: 768px) {
            flex-direction: column;
            gap: 4vw;
          }

        .buttons_editBtn {
            button {
                height: 55px;
            }
        }

        .buttons_discountField {
            display: flex;
            gap: 4vw;

            .buttons_discountField_kek {
                
                .discountField_title {

                }

                .discountField_value {
                    @include defaultFiledStyle;
                    height: 30px;
                }
            }

        }

        .buttons_resultField {
            @include defaultFiledStyle;
            width: 20%;
            height: 55px;
        }
    }

    .tableBox_table {
        background-color: $themeColorGray;
        border-radius: $largeBorderRadius;
        padding: $defaultSmallMargin;

        .scroll_wrapper {
            @include flexColumn;
            height: 400px;
            overflow-x: hidden;
        }
    }
}
