@import '../../../../app/styles/variables/mixins';
@import '../../../../app/styles/variables/variables';

.appBar {
  height: var(--navbar-height);
  background-color: var(--header-color);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  //@include flexCenter;


  .content {
    //width: 91%;
    //display: grid;
    //grid-template-columns: 1fr 1fr 1fr;
    //grid-template-areas: "leftSide center rightSide";

    height: var(--navbar-height);
    @include defaultContainer;
    padding: $defaultLargeMargin;
    @include flexBetween;

    @media screen and (max-width: 440px) {
      padding: 8px;
    }

    .leftSide {
      display: flex;
      align-items: center;
      gap: 20px;

      .leftSide_burgerMenu {
        position: relative;

        .burgerMenu_iconButton {
          @media screen and (max-width: 440px) {
            font-size: 12px;
          }
        }

        .burgerMenu_menuList {
          position: absolute;
          background-color: var(--header-color);
          //border-radius: $defaultSmallMargin;
          border: 1px solid white;
          padding: $defaultLargeMargin;
          min-width: 230px;
          z-index: 100;

          .menuList_item {
            padding: 3px 0;

            &:hover {
              cursor: pointer;
              background-color: $themeColorGray;
            }
          }

          .menuList_itemNoClick {
            padding: 3px 0;
            text-decoration: underline;
          }

          .subMenuList_item {
            margin-left: 15px;
            padding: 3px 0;

            &:hover {
              cursor: pointer;
              background-color: $themeColorGray;
            }
          }
        }
      }

      .leftSide_shopTitle {
        @include hoverCursorPointer;
      }
    }

    .center {
      @include flexCenter;
      gap: 20px;

      .clock {
        @include flexCenter;
        width: 75px;
      }

      .currency {
        width: 130px;

        @media screen and (max-width: 640px) {
          display: none;
        }
      }
    }

    .rightSide {
      display: flex;
      gap: 20px;
      align-items: center;
      justify-content: flex-end;

      @media screen and (max-width: 440px) {
        gap: 0;
      }
    }
  }
}

.css-13cymwt-control {
  @media screen and (max-width: 640px) {
    display: none;
  }
}