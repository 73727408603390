@import '../../../app/styles/variables/mixins';

.table {
  background-color: rgb(18, 18, 18);
  background-image: linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05));
  color: var(--primary-color);
  width: 100%;
  //overflow: auto;
  border-collapse: collapse;
  border-style: hidden;
  box-shadow: 0 0 0 1px rgb(81, 81, 81);
  height: 100%;

  @media screen and (max-width: 1280px) {
    min-height: 450px;
  }
}

.thead, .tbody tr {
  display: table;
  width: 100%;
  //height: 20px;
  table-layout: fixed;
  font-size: 14px;
  word-break: break-word;
}

.thead {
  border-bottom: 1px solid rgb(81, 81, 81);
  text-align: center;
}

.tbody {
  display: block;
  //max-height: 860px;
  height: 86%; // ?
  //height: 75vh; // ?
  overflow: auto;
}

.head_items {

}

.head_item {
  padding: 5px;
  border-right: 1px solid rgb(81, 81, 81);

  height: 20px;
  //white-space: nowrap;
  //overflow: hidden;
  //text-overflow: ellipsis;

  @media screen and (max-width: 440px) {
    font-size: 2.4vw;
  }
}

.tbody {
  display: block;
  text-align: center;
}

.body_items {
  border-bottom: 1px solid rgb(81, 81, 81);

  //word-break: break-word;
  //height: 50px;
  //white-space: nowrap;
  //overflow: hidden;
  //text-overflow: ellipsis;
}

.body_items:hover {
  background-color: rgb(18, 18, 18);
}

.body_item {
  padding: 5px;

  .currency_inputClassName {
    word-break: break-word;
  }
}

.rowSelectedBackground {
  background: #5C636A;
}

.rowSelectedBackground:hover {
  background: #4b4b4b;
}

// 1 - колонка с ценой
.price_column {
  @include flexCenter;
  gap: 5px;
}

.currency_inputClassName1 {
  width: 100px;
  text-align: center;
  //background: none;
}

.currency_spanClassName1 {
  font-size: 14px;
}

// 2 - название, количество, описание
.currency_inputClassName2 {
  width: 150px;
  text-align: center;
}

.currency_spanClassName2 {
  font-size: 14px;
}