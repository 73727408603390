@import '../../../app/styles/variables/mixins';
@import '../../../app/styles/variables/variables';

.supplyInvoiceArchiveModal_item {
    @include flexOnlyBetween;
    background-color: $themeColorGray;
    border: 1px solid $themeColorMilk;
    border-radius: $smallBorderRadius;
    padding: $defaultSmallMargin;
    @include hoverCursorPointer;

    .item_content {
        @include flexBetween;

        @media screen and (max-width: 640px) {
            flex-direction: column;
            width: 100%;
        }

        .content_info {
            height: 100%;
            max-width: 300px;
            font-size: 20px;
            border-right: 2px solid $themeColorMilk;
            padding-right: 10px;
            @include flexColumnOnly;
            justify-content: space-between;
            align-items: flex-start;
            @include divSmallMarginBottom;

            @media screen and (max-width: 640px) {
                flex-direction: row;
                border-bottom: 1px solid #c9c9c9;
                margin: 0 0 0.2vw 0;
                width: 100%;
                max-width: 500px;
                border-right: none;
                text-align: center;
            }

            .cashBlock {
                @include flexCenter;

                .cashBlock_info {
                    padding-left: 10px;
                    font-size: 20px;

                    @media screen and (max-width: 1440px) {
                        font-size: 1.4vw;
                    }

                    @media screen and (max-width: 768px) {
                        font-size: 2.2vw;
                    }

                    @media screen and (max-width: 440px) {
                        font-size: 2.6vw;
                    }                    
                }
            }
        }

        @include modalDateBlock;
    }
}