@import '../../../app/styles/variables/mixins';
@import '../../../app/styles/variables/variables';

.wrapper {
  @include wrapperMainBlock;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  gap: 15px;
  overflow: hidden;
  height: 1vw;

  .head {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 5px;
    gap: 10px;

    @media screen and (max-width: 1280px) {
      flex-direction: column;
    }

    .head_search {
      flex-grow: 100;
    }
  }

  .body {
    overflow: hidden;
    border-radius: 20px;
    background-color: #757575;
    flex-grow: 100;
    padding: 10px;

    .lostcallbody {
      height: 100%;
    }

    .coldclientbody {
      height: 100%;
    }

    .hotclientbody {
      height: 100%;
    }

    .deals {
      height: 95%;

      @media screen and (max-width: 440px) {
        height: 90%;
      }
    }
  }
}