@import '../../../../app/styles/variables/mixins';
@import '../../../../app/styles/variables/variables';

.createProductModal_mainBox {
  @include defaultModalStyle;
  border-radius: $smallBorderRadius;

  .createProductModal_inputs {
    margin-bottom: $defaultLargeMargin;
    @include divSmallMarginBottom;
  }

  .createProductModal_buttons {
    @include workAndProductModalsButtonBlock;
  }
}