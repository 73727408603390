@import '../../app/styles/variables/mixins';
@import '../../app/styles/variables/variables.scss';

.wrapper {
  @include wrapperMainBlock;
  @include flexOnlyBetween;
  gap: $defaultLargeMargin;

  .main_block {
    background-color: $themeColorDark;
    border-radius: $largeBorderRadius;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    padding: 20px;
    gap: 20px;
    overflow: clip;

    @media screen and (max-width: 1280px) {
      flex-direction: column;
      width: 100%;
    }

    > div {
      @media screen and (max-width: 1280px) {
        width: 100%;
      }
    }

    .column {
      //border-right-style: solid;
      width: 22%;
      height: 85vh;

      @media screen and (max-width: 1280px) {
        width: 100%;
      }


      .column_name {
        padding-bottom: 15px;
        border-bottom-style: solid;
        text-align: center;
        font-size: 2.6vw;
        font-weight: bolder;
        padding-top: 5px;

        @media screen and (max-width: 440px) {
          font-size: 4.6vw;
        }
      }

      .column_add_button {
        font-size: 50px;
        font-weight: bold;
        background-color: #32DA75;
        border-radius: 5px;
        color: black;
        text-align: center;
        height: 50px;
        margin-right: 10px;
        margin-left: 10px;
        margin-top: 10px;
      }

      .column_add_button:hover {
        background-color: #2aa25a;
      }

      .column_orders {
        margin-top: 20px;
        overflow: auto;
        height: 83%;

        .column_order {
          background-color: #D9D9D9;
          border-radius: 5px;
          height: 200px;
          margin-right: 10px;
          margin-left: 10px;
          margin-top: 10px;

          color: black;
          display: flex;
          flex-direction: column;

          padding: 10px;

          .order_first_row {
            height: 40px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            border-bottom: 2px black solid;
            padding-bottom: 5px;

            .first_row_number {
              display: flex;
              flex-direction: row;
              padding: 2px;
              line-height: 25px;

              .first_row_number_number {
                margin-left: 5px;
                font-weight: 550;
              }
            }

            .first_row_status {
              color: white;
              font-weight: 550;
              height: 100%;
              border-radius: 5px;
              background-color: #E37C79;
              width: 100%;
              margin-left: 10px;

              display: flex;
              justify-content: center;
              align-items: center;
              padding: 2px;
            }

            .first_row_status:hover {
              padding: 0;
              border: 1px black solid;
              cursor: pointer;
            }
          }

          .order_second_row {
            padding-top: 5px;
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            height: 100%;
            border-radius: 10px;
            gap: 10px;

            .order_second_row_user {
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: space-around;

              .order_second_row_user_manager {

              }

              .order_second_row_user_timing {

              }
            }

            .order_second_row_data {
              width: 100%;
              display: flex;
              flex-direction: column;

              .order_second_row_data_numbers {
                width: 100%;
                display: flex;
                justify-content: space-around;

                .order_second_row_data_numbers_quantity {
                }

                .order_second_row_data_numbers_price {

                }
              }

              .order_second_row_data_status {
                border-radius: 5px;
                color: white;
                font-weight: 600;
                background: #ff6161;
                margin: 5px;
                padding: 5px;
                text-align: center;
              }

              .order_second_row_data_delivery {
                text-align: center;
                font-weight: 800;
                font-size: 18px;
                overflow: hidden;
                height: 35px;
              }
            }
          }

          .order_third_row {
            border-radius: 10px;
            margin-top: 10px;
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            height: 100%;
            gap: 10px;

            .order_third_row_crm {
              width: 100%;

              .order_third_row_crm_actions {
                display: flex;
                flex-direction: column;
                width: 100%;
                justify-content: space-between;
                height: 100%;

                .action_row1 {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  font-weight: 600;
                  font-size: 16px;
                }

                .action_row2 {
                  display: flex;
                  justify-content: space-around;

                  .crm {
                    border-radius: 5px;
                    background-color: #4b4b4b;
                    color: #d9d9d9;
                    font-weight: 800;
                    font-size: 20px;
                    padding: 5px;
                    display: flex;
                    align-items: center;
                  }

                  .crm:hover {
                    background-color: #2c2c2c;
                    color: #ffffff;
                    cursor: pointer;
                  }
                }

              }

              .order_third_row_crm_last {

              }
            }

            .order_third_row_action {
              width: 100%;

              .order_third_row_action_main {
                height: 40%;
                margin: 5px;
                background: #5acb87;
                border-radius: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: 600;
                font-size: 16px;
              }

              .order_third_row_action_main:hover {
                background: #2aa25a;
                cursor: pointer;
              }

              .order_third_row_action_third {
                height: 40%;
                margin: 5px;
                color: #4b4b4b;
                background: #bebebe;
                border-radius: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: 600;
                font-size: 16px;
              }

              .order_third_row_action_third:hover {
                background: #737373;
                color: #000000;
                cursor: pointer;
              }

              .order_third_row_action_second {
                height: 40%;
                margin: 5px;
                background-color: #ffb46d;
                color: #7e7e7e;
                border-radius: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: 600;
                font-size: 14px;
              }

              .order_third_row_action_second:hover {
                background: #ff9d3f;
                color: #000000;
                cursor: pointer;
              }
            }
          }

        }

        .column_order:hover {
          background-color: #bbbbbb;
        }
      }

    }
  }
}