@import '../../../app/styles/variables/mixins';
@import '../../../app/styles/variables/variables';

.serviceBlock {
  @include wrapperMainBlock;
  display: flex;
  gap: $defaultLargeMargin;

  @media screen and (max-width: 1280px) {
    flex-direction: column;
  }

  .service_leftSide {
    @include flexColumn;
    width: 25%;

    @media screen and (max-width: 1280px) {
      width: 100%;
    }

    .leftSide_buttons {
      border-radius: $largeBorderRadius;
      background-color: $themeColorDark;
      padding: $defaultLargeMargin;
      margin-bottom: $defaultLargeMargin;
      width: 100%;

      .buttons_filter {
        width: 100%;
        margin-bottom: $defaultLargeMargin;

        display: flex;
        gap: 5px;

        button {
          width: 100%;
          padding: 5px 10px;
        }
      }

      .content_title {
        border-radius: $largeBorderRadius;
        background-color: $themeColorGray;
        padding: $defaultLargeMargin;
        @include flexColumn;
        justify-content: center;
        height: 88px;

        @include divSmallMarginBottom;

        .content_startBtn {
          width: 100%;
          @include flexCenter;

          button {
            width: 100%;
            padding: 10px 0;
          }
        }

        .content_inProcessButtons {
          @include flexOnlyBetween;
          gap: $defaultSmallMargin;
        }

        .content_doneButtons {
          @include flexOnlyBetween;
          gap: $defaultSmallMargin;
        }
      }

    }

    .leftSide_content {
      border-radius: $largeBorderRadius;
      background-color: $themeColorDark;
      padding: $defaultLargeMargin;
      @include widthHeightFull;
      height: 100%;
      display: flex;

      @media screen and (max-width: 1280px) {
        max-height: 500px;
        overflow-y: scroll;
      }

      .content_info {
        width: 100%;
        height: 100%;
        @include flexColumn;
        margin-top: $defaultSmallMargin;
        text-align: center;
        overflow-y: auto; // надо будет пофиксить!
        @include divSmallMarginBottom;
        padding-right: 5px;

        .serviceItem {
          @include hoverItem;
          @include serviceItemCommonStyles;
        }

        .serviceItem_active {
          background-color: $themeColorGray;
          cursor: default;
          @include serviceItemCommonStyles;
        }

        .serviceItem_WaitingSupply {
          @include hoverItem;
          color: $filterButtonWaitingColorExtra;
        }

        .serviceItem_selected {
          @include hoverItem;
          //background-color: #4b4b4b;
          @include serviceItemCommonStyles;
        }
      }
    }
  }

  .service_rightSide {
    @include flexColumnOnly;
    border-radius: $largeBorderRadius;
    background-color: $themeColorDark;
    padding: $defaultLargeMargin;
    width: 75%;

    @media screen and (max-width: 1280px) {
      width: 100%;
    }

    .rightSide_stuffInput {
      @include flexCenter;
      margin-bottom: $defaultLargeMargin;
      width: 100%;
    }

    .rightSide_infoFields {
      @include flexOnlyBetween;
      margin-bottom: $defaultLargeMargin;
      width: 100%;
      gap: $defaultLargeMargin;

      @media screen and (max-width: 768px) {
        flex-direction: column;
      }

      .infoFields_content {
        @include flexColumnOnly;
        @include divLargeMarginBottom;
        justify-content: space-between;
        width: 60%;

        @media screen and (max-width: 768px) {
          width: 100%;
        }

        .content_detailsInput {
          height: 100%;

          @media screen and (max-width: 768px) {
            flex-direction: column;
          }

          //input {
          //    min-height: 80px;
          //}
        }

        .content_selectMaster {
          color: black;

          .error {
            color: red;
          }
        }

        .content_buttons {
          @include flexBetween;
          gap: $defaultLargeMargin;

          .content_saveBtn {
            z-index: 0;
            width: 100%;

            button {
              width: 100%;
              height: $largeButtonHeight;
            }

          }

          .content_sumField {
            @include defaultFiledStyle;
            width: 200px;
            height: $largeButtonHeight;
          }
        }

      }

      .infoFields_clientCard {
        @include flexColumnOnly;
        justify-content: space-between;
        width: 40%;
        gap: 2vw;

        @media screen and (max-width: 768px) {
          width: 100%;
        }

        .clientCard_cancelButton {
          @include flexCenter;
          margin-top: $defaultSmallMargin;
          width: 100%;

          button {
            width: 100%;
            height: 50px;
          }
        }
      }
    }

    .rightSide_tables {
      @include flexBetween;
      @include widthHeightFull;
      column-gap: 20px;
      height: auto;

      @media screen and (max-width: 1280px) {
        flex-direction: column;
        gap: 3vw;
      }
    }
  }
}

.InstantWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .InstantYes {
    width: 500px;
    height: 50px;
    margin-bottom: 10px;
  }

  .InstantNo {
    width: 500px;
    height: 50px;
  }
}