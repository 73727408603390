@import '../../../app/styles/variables/mixins';
@import '../../../app/styles/variables/variables';

.optionModal_mainBlock {
    @include defaultModalStyle;
    @include flexColumnOnly;
    gap: $defaultLargeMargin;
    min-width: 500px;

    .optionModal_createOption {
        padding: $defaultSmallMargin;
        background-color: $themeColorGray;
        border-radius: $smallBorderRadius;

        .createOption_box {
            border-radius: $smallBorderRadius;

            .optionName_row {
                @include flexBetween;
                gap: 20px;
                margin: 5px 0 10px 0;

                .optionName_rowInput {
                    width: 100%;
                }

                button {
                    @include flexCenter;
                    width: 40px;
                    height: 40px;
                    font-size: 30px;
                }
            }
            .optionVariantName_row {
                @include flexBetween;
                gap: 10px;
                margin-bottom: 10px;

                .optionVariantName_rowInput {
                    width: 100%;
                }

                button {

                }
            }

            .optionVariant_list {
                padding: $defaultSmallMargin;
                //border-radius: $smallBorderRadius;
                border: 1px solid $themeColorMilk;
                display: flex;
                flex-wrap: wrap;
                gap: 10px;
                //height: 145px; // 3 строки
                height: 100px; // 2 строки
                overflow: auto;
                margin-bottom: 10px;

                .optionVariant_emptyList {
                    @include flexCenter;
                    width: 100%;
                }

                .optionVariant_item {
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    background-color: $themeColorMilk;
                    border-radius: $smallBorderRadius;
                    padding: 5px 5px 5px 7px;
                    height: 35px;

                    .item_text {
                        color: black;

                        .inputClassName {
                            background-color: transparent;
                            height: 30px;
                            border: none;
                            font-size: 16px;
                            width: 150px;

                            //input {
                            //    width: 100%;
                            //    max-width: 100%;
                            //}
                        }
                        .spanClassName {
                            width: min-content;
                        }
                    }
                    .item_delete {
                        img {
                            width: 25px;
                            height: 25px;

                            &:hover {
                                cursor: pointer;
                                filter: contrast(3);
                            }
                        }
                    }
                }
            }
            .createOption_buttonsBlock {
                display: flex;
                justify-content: space-between;
                gap: 20px;

                button {
                    min-width: 100%;
                }

                > div {
                    width: 50%;
                }

                //.buttonsBlock_cancelButton {
                //
                //}
                //.buttonsBlock_createButton {
                //
                //}
            }
        }
    }

    .optionModal_options {
        padding: $defaultSmallMargin;
        background-color: $themeColorGray;
        border-radius: $smallBorderRadius;

        .options_title {
            margin-bottom: 10px;
            text-align: center;
        }

        .optionModal_optionsList {
            @include divSmallMarginBottom;
            padding-right: $defaultSmallMargin;
            overflow: auto;
            max-height: 350px;

            .optionFieldset_wrapper {
                @include currentOption(white)
            }
            .optionFieldset_wrapper_active {
                @include currentOption(orange)
            }
        }
    }
}