.wrapper {
  display: flex;
  gap: 5px;
  user-select: none;
  width: 100%;
  justify-content: center;

  .label {
    background: #4b4b4b;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px;
  }

  .label:hover {
    cursor: pointer;
    background: #363636;
  }

  .labelSel {
    background: #bbbbbb;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px;
  }

  .labelSel:hover {
    background: #f5f5f5;
    cursor: pointer;
  }
}