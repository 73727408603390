@import '../../../app/styles/variables/mixins';
@import '../../../app/styles/variables/variables';

.optionBind {
  background-color: $themeColorMilk;
  padding: $defaultLargeMargin;
  border-radius: $smallBorderRadius;
  width: 100%;

  .optionBind_header {
    @include flexBetween;
    margin-bottom: $defaultSmallMargin;
    color: black;
    gap: 10px;

    .search_wrapper {
      width: 100%;
    }

    .headerOptions_search {
      width: 400px;
    }

    .addBindButton {
      button {
        width: 300px;
      }
    }
  }

  .optionBind_productBlock {
    @include flexOnlyBetween;
    gap: $defaultSmallMargin;
    margin-top: $defaultSmallMargin;

    .productBlock_productContent {
      background-color: $themeColorDark;
      border-radius: $smallBorderRadius;
      padding: $defaultSmallMargin;
      width: 100%;

      .productContent_info {
        .pci_name {
          display: flex;

          .pci_number {
            padding-right: 20px;
            font-weight: bold;
            font-size: larger;
          }

          .pci_name_name {
            font-weight: bold;
            font-size: larger;
            padding-right: 10px;
            padding-left: 10px;
          }
        }

        display: flex;
        justify-content: space-between;
        margin-bottom: $defaultSmallMargin;
        text-align: center;
      }

      .productContent_contentBlock {
        @include flexOnlyBetween;
        width: 100%;
        gap: 10px;

        .productBlock_deleteBindProduct {
          display: flex;
          justify-content: flex-end;

          img {
            width: 40px;
            height: 40px;

            &:hover {
              cursor: pointer;
              filter: contrast(3);
            }
          }
        }

        .productBlock_imageBlock {
          padding: $defaultLargeMargin;

          .productImage {

            .productImage_mainImage {
              @include flexCenter;
              margin-bottom: $defaultSmallMargin;

              // заглушка, если нету фотографии
              //.item_noImage {
              //    @include flexColumn;
              //
              //    .item_noImage_title {
              //        padding: 20px 0 10px 0;
              //    }
              //
              //    img {
              //        width: auto;
              //        height: 120px;
              //    }
              //}

              img {
                width: 100px;
                height: 100px;
                object-fit: cover;
              }
            }

            .addBindedImage_box {
              //position: relative;
              //
              //.addBindedImage_deleteItem {
              //    @include deleteIconButton;
              //}

              .deleteBindedPhoto {
                @include flexCenter;
                margin-bottom: $defaultSmallMargin;
                @include deleteButton;
              }

              .addBindedImage {
                //@include inputTypeFile($themeColorMilk);

                .inputButton {
                  display: flex;
                  text-align: center;
                  background-color: #1876D1;
                  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.3);
                  border-radius: 0.25rem;
                  padding: 5px 10px;
                  line-height: 1.2;

                  &:hover {
                    cursor: pointer;
                    background-color: #1c508a;
                  }
                }
              }
            }
          }
        }

        .contentBlock_content {
          @include flexOnlyBetween;
          gap: $defaultSmallMargin;
          width: 100%;

          // опции
          .content_options {
            width: 100%; // ширина блока

            .options_wrapperBox {
              border-radius: $smallBorderRadius;
              //height: 100%;

              .options_selectRow {
                @include flexCenter;
                width: 100%;
                gap: $defaultSmallMargin;
                margin-bottom: $defaultSmallMargin;

                .options_select {
                  width: 100%;
                  color: black;
                }

                .options_addButton {

                  button {

                    height: 38px;
                  }
                }
              }

              .options_scrollContainer {
                overflow-y: auto;

                //height: 150px; // высота скролл-блока
                padding-right: 5px;

                .options_list {
                  color: black;

                  .options_listItem {
                    @include flexBetween;
                    margin-bottom: $defaultSmallMargin;
                    padding: 5px;
                    border-radius: 5px;

                    .listItem_title {
                      color: white;
                    }

                    .listItem_selectWithButton {
                      @include flexBetween;
                      gap: $defaultLargeMargin;

                      .add_variant_button {
                        color: white;
                        background-color: #2aa25a;
                      }

                      .add_variant_button_disabled {
                        background-color: #37423b;
                      }

                      .add_variant_button:hover {
                        background-color: #28e373;
                      }

                      .add_variant_button:disabled {
                        background-color: #485d4d;
                        color: #726565;

                      }

                      .options_search {
                        width: 250px;
                      }

                      @include deleteButton;
                    }
                  }
                }
              }

            }
          }

          // теги
          .content_tags {
            width: 40%; // ширина блока


            .tags_wrapperBox {
              border-radius: $smallBorderRadius;

              .tags_addButton {
                margin-bottom: $defaultSmallMargin;

                button {
                  width: 100%;
                  height: 38px;
                }
              }

              .tags_scrollContainer {
                overflow-y: auto;
                height: 150px; // высота скролл-блока
                color: black;
                padding-right: 5px;

                .tags_list {
                  display: flex;
                  flex-wrap: wrap;
                  gap: $defaultSmallMargin;
                  overflow: auto;
                  margin-bottom: $defaultSmallMargin;
                  padding-right: $defaultSmallMargin;

                  .tags_listItem {
                    background-color: $themeColorMilk;
                    border-radius: $smallBorderRadius;
                    @include flexCenter;
                    padding: 5px 5px 5px 7px;
                    height: 30px;

                    .item_name {
                      margin-right: 5px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}