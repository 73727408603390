.editableSpan_input {
  border-radius: 5px;
  display: flex;
  align-items: center;
  word-break: break-word;
  font-size: 16px;
  height: 40px;
  width: 100%;
}

.editableSpan_text {
  composes: editableSpan_input;
}