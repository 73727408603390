.wrapper {
  height: 100%;
  width: 100%;
  color: black;
  display: flex;
  flex-direction: column;
  border-radius: 10px;

  .buttons {
    padding: 5px;
    display: flex;
    justify-content: space-between;
    gap: 5px;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      justify-content: center;
      flex-wrap: wrap;
      height: 80px;
    }


    .action {
      height: 32px;
      display: flex;
      justify-content: space-between;
      gap: 5px;


      .but {
        height: 30px;
      }

    }

    .type {
      display: flex;
      gap: 5px;
      align-items: center;

      .type_kind {
        border-radius: 5px;
        background-color: black;
        height: 25px;
        width: 25px;
      }

      .type_kind:hover {
        border: 1px black solid;
      }
    }
  }

  .table {
    padding: 5px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 5px;
    background-color: #858585;
    border-radius: 10px;
    height: 100%;

    @media screen and (max-width: 768px) {
      height: 200px !important;
    }

    .row {
      border-radius: 5px;
      background: #d9d9d9;
      width: 100%;
      padding: 5px;
      display: flex;
      gap: 10px;
      height: 72px;

      .type {
        display: flex;
        flex-direction: column;
        gap: 5px;
        width: 30px;

        .icon {
          height: 30px;
          width: 30px;
          border-radius: 5px;

        }

        .time {
          display: flex;
          flex-direction: column;
          gap: 3px;
          align-items: center;

          .time_info {
            text-align: center;
            font-weight: 600;
            font-size: 12px;
          }
        }
      }

      .data {
        overflow: auto;


        .thematic {
          font-size: 16px;
          font-weight: 600;
        }

        .core {
          font-size: 14px;
          font-weight: 500;
        }
      }
    }

    .row:hover {
      background: #b4b4b4;

    }
  }
}