@import '../../app/styles/variables/mixins';
@import '../../app/styles/variables/variables';

.discountModal_mainBox {
  @include defaultModalStyle;
  max-width: 500px; // замена ширины из миксина

  @media screen and (max-width: 440px) {
    width: 340px;
  }

  .discountModal_selectBlock {
    margin-bottom: $defaultLargeMargin;

    .select_box {
      color: black;
    }
  }

  .discountModal_buttonsBlock {
    @include flexColumn;
    position: relative;
    z-index: -100;

    button {
      width: 170px;
      padding: $smallButtonPadding;

      &:first-child {
        margin-bottom: $defaultSmallMargin;
      }
    }
  }
}
