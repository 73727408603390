@import '../../app/styles/variables/mixins';
@import '../../app/styles/variables/variables.scss';

.wrapper {
  @include wrapperMainBlock;
  @include flexOnlyBetween;
  gap: $defaultLargeMargin;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }

  .left {
    background-color: $themeColorDark;
    border-radius: $largeBorderRadius;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 70%;
    padding: 20px;
    gap: 20px;

    @media screen and (max-width: 768px) {
    width: 100%;
  }
  }

  .right {
    background-color: $themeColorDark;
    border-radius: $largeBorderRadius;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 30%;
    padding: 20px;
    gap: 20px;

    @media screen and (max-width: 768px) {
    width: 100%;
  }

    .search {
      width: 100%;
    }

    .data_text {
      width: 80%;

      .line {
        margin-bottom: 5px;
      }

      .fio {
        font-weight: bolder;
        font-size: larger;
      }
    }

  }
}