@import '../../../../app/styles/variables/mixins';
@import '../../../../app/styles/variables/variables';

.addProductCardTagModal_mainBox {
  @include defaultModalStyle;
  @include flexColumn;
  border-radius: $smallBorderRadius;

  .addProductCardTagModal_content {

  }
}