.wrapper {
  border-radius: 20px;
  padding: 10px;

  .label {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    padding-bottom: 15px;
  }

  .item {
    word-break: break-word;
    padding-bottom: 5px;
    display: flex;
    justify-content: space-between;
  }
}