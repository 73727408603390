.wrapper {
  background: white;
  width: 250px;
  height: 156px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .name {
    font-weight: bolder;
    color: black;
    text-align: center;
    font-size: 14px;
    margin-top: 3px;
    max-height: 30px;

    //max-width: 290px;
    //height: 40px;
    //white-space: nowrap;
    //overflow: hidden;
    //text-overflow: ellipsis;
  }

  .catalog_key {
    font-weight: bolder;
    color: black;
    text-align: left;
    font-size: 10px;

    max-height: 30px;

    //max-width: 290px;
    //height: 40px;
    //white-space: nowrap;
    //overflow: hidden;
    //text-overflow: ellipsis;
  }

  .price {
    color: black;
    text-align: center;
    font-weight: bold;
    font-size: x-large;
  }

  .barcode {

  }
}