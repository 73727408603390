@import '../../../app/styles/variables/mixins';
@import '../../../app/styles/variables/variables';

.editProductCardModal {
  @include defaultModalStyle;
  min-width: 1400px;

  .editProductCardModal_mainBlock {
    @include flexColumnOnly;

    .editProductCardModal_header {
      @include flexBetween;
      margin-bottom: $defaultLargeMargin;
      max-height: 50px;

      .productInfo {
        @include flexCenter;
        width: 400px;
        height: 60px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        border: 2px solid $themeColorMilk;
        border-radius: $smallBorderRadius;
        padding: $defaultSmallMargin;
      }
    }

    //контейнер для скролла
    .editProductCardModal_scrollContainer {
      overflow-y: scroll;
      height: 90vh;
      padding-right: 5px;

      display: grid;
      gap: $defaultLargeMargin;
      grid-template-columns: 40% 60%;
      grid-template-areas:
                    'gallery optionBind'
                    'fullEditor optionBind';

      .optionBind_wrapper {
        grid-area: optionBind;
      }

      .imageGallery {
        grid-area: gallery;
      }

      .fullEditor {
        grid-area: fullEditor;
      }
    }
  }
}