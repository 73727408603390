@import '../../../app/styles/variables/mixins';
@import '../../../app/styles/variables/variables';

.stuffProfileMainBlock {
    @include profileAdminMenuStyles;

    @media screen and (max-width: 640px) {
        flex-direction: column;
    }

    .profile_content {
        width: 75%;
        @include flexColumn;
        gap: $defaultLargeMargin;

        @media screen and (max-width: 640px) {
            flex-direction: column;
            width: 100%;
        }

        > div {
            border-radius: $largeBorderRadius;
            background-color: $themeColorDark;
            padding: $defaultLargeMargin;
            width: 100%;
        }

        .content_title {
            height: 80px;
            @include flexBetween;

            .title_name {
                width: 100%;
            }
            .title_textInput {

            }
        }
        .content_info {
            height: 100%;

            @media screen and (max-width: 640px) {
                min-height: 500px;
            }
        }
    }
}