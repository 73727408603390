.app_theme_dark {
    --primary-color: #f8f8f8;
    --inverted-primary-color: #505050;
    --button-disabled-color: #5C636A;
    --button-hover-bgcolor: #272727;
    --button-hover-color: white;

    --button-active-bgcolor: white;
    --button-active-color: black;

    --bg-color: #000000;
    --header-color:#272727;
}
