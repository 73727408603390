@import '../../../app/styles/variables/mixins';

.textarea_wrapper {
    border: 1px solid white;
    border-radius: 4px;
    width: 100%;
    height: 100%;
    opacity: 0.7;

    &:hover {
        opacity: 1;
    }

    textarea {
        resize: none;
        width: 100%;
        height: 100%;
        padding: 10px 20px;
        font-size: 16px;
        color: white;
        border: none;
        background-color: transparent;
    }

    ::placeholder {
        color: white;
        opacity: 0.7;
    }
}