@import '../../../app/styles/variables/mixins';
@import '../../../app/styles/variables/variables';

.encashmentModal_mainBox {
    @include defaultModalStyle;
    @include flexColumn;
    width: 350px;
    text-align: center;
    @include divLargeMarginBottom;

    .encashment_header {
        .title {
            font-weight: bold;
            font-size: 24px;
            margin-bottom: 10px;
        }
        .date {
            font-size: 20px;
        }
    }
    .encashment_content {
        width: 100%;
        @include divSmallMarginBottom;
        @include encashmentModalTitle;

        .content_report {
            @include encashmentTextarea(100%, 130px);
        }
    }
    .encashment_buttons {
        width: 100%;
        @include flexColumnOnly;
        gap: 10px;

        button {
            width: 100%;
            height: 40px;
        }
    }
}