@import '../../../app/styles/variables/mixins';
@import '../../../app/styles/variables/variables';

.serviceFinalArchiveModal_mainBlock {
  @include defaultModalStyle;
  width: 700px;

  @media screen and (max-width: 768px) {
    width: 90vw;
    max-height: 500px;
    overflow-y: scroll;
  }

  @media screen and (max-width:640px) {
    flex-direction: row;
  }

  .serviceFinalArchiveModal_title {
    @include modalTitle;
  }

  .serviceFinalArchiveModal_list {
    @include divLargeMarginBottom;
    max-height: 550px;
    overflow: auto;
    padding-right: 10px;

    .supplyInvoiceArchiveModal_item {
      @include flexOnlyBetween;
      background-color: $themeColorGray;
      border: 1px solid $themeColorMilk;
      border-radius: $smallBorderRadius;
      padding: $defaultSmallMargin;
      @include hoverCursorPointer;

      .item_content {
        @include flexBetween;

        @media screen and (max-width: 640px) {
          flex-direction: column;
          width: 100%;
          gap: 1.6vw;
        }

        @media screen and (max-width: 440px) {
          align-items: flex-start;
        }

        .content_info {
          height: 100%;
          width: 300px;
          font-size: 20px;
          border-right: 2px solid $themeColorMilk;
          padding-right: 10px;
          @include flexColumnOnly;
          justify-content: space-between;
          align-items: flex-start;
          @include divSmallMarginBottom;

          @media screen and (max-width: 640px) {
            border-right: none;
            margin: 0 0 0.6vw 0;
            padding: 0 0 0.4vw 0;
            border-bottom: 1px solid #c9c9c9;
            width: 100%;
          }

          .cashBlock {
            @include flexCenter;

            .cashBlock_img {
              img {
                width: 35px;
                height: 35px;
              }
            }

            .cashBlock_info {
              padding-left: 10px;
              font-size: 20px;

              @media screen and (max-width: 640px) {
                font-size: 3vw;
              }
            }
          }
        }

        @include modalDateBlock;
      }
    }
  }
}