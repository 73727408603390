@import '../../../../app/styles/variables/mixins';
@import '../../../../app/styles/variables/variables';

.productTagCloud {
    .productTagCloud_header {
        @include flexBetween;
        margin-bottom: 10px;
        @include deleteButton;
    }
    .productTagCloud_tagsList {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        height: 100px;
        overflow: auto;

        .tagsList_item {
            background-color: $themeColorGray;
            border-radius: $smallBorderRadius;
            @include flexCenter;
            padding: 5px 5px 5px 7px;
            height: 30px;

            &:hover {
                background: #272727;
                cursor: pointer;
            }
        }
    }
}
