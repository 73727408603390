@import '../../app/styles/variables/mixins';
@import '../../app/styles/variables/variables';

.confirmModal_mainBox {
    @include defaultModalStyle;
    @include flexColumn;
    width: 350px;

    .confirmModal_title {
        margin-bottom: $defaultLargeMargin;
        text-align: center;
    }
    .confirmModal_buttons {
        display: flex;
        gap: $defaultExtraLargeMargin;

        button {
            padding: $defaultSmallMargin;
        }

        .confirmModal_cancelButton {
            @include deleteButton;
        }
    }
}