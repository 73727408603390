@import '../../../app/styles/variables/mixins';
@import '../../../app/styles/variables/variables';

.wrapper {
  @include wrapperMainBlock;
  height: 100%;
  overflow: hidden;

  .head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    align-items: center;

    @media screen and (max-width: 540px) {
      flex-direction: column;
      justify-content: flex-start;
      padding: 20px 0;
    }

    .select {
      width: 100%;
      color: black;
      padding: 5px;
    }

    .clock {
      color: white;
      font-weight: 600;
      border-radius: 5px;
      border: 1px white solid;

      .clc {
        display: inline;
      }
    }
  }


  .calendar {
    background-color: #33373B;
    display: flex;
    flex-direction: row;
    border: 1px white solid;
    border-radius: 5px;
    padding: 5px;
    overflow: hidden;

    .main_column {
      padding: 5px;
      border-right: 3px white solid;
      padding-top: 40px;
      display: flex;
      flex-direction: column;
      margin-right: 5px;

      .user {
        padding: 5px;
        border-radius: 5px;
        margin-bottom: 34px;
      }
    }

    .columns {
      display: flex;
      flex-direction: row;
      overflow: auto;

      .column {
        margin: 3px;
        padding: 3px;
        border-radius: 5px;
        height: 500px;
        display: flex;
        flex-direction: column;

        .cell {
          font-weight: 600;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          width: 170px;
          height: 45px;
          margin: 15px 3px 3px 3px;
          padding: 3px;
          border-radius: 15px;

          .cell_left {
            text-align: right;
            border-right: 2px black solid;
            margin: 1px;
            margin-right: 5px;
            padding-right: 5px;
          }

          .cell_right {
          }
        }

        .cell:hover {
          border: 2px black solid;
          padding: 1px;
        }
      }

      .column:hover {
        background-color: #545454;
      }
    }
  }

  .history {
    margin-top: 10px;
    height: 180px;
    display: flex;
    gap: 5px;
    flex-direction: row;
    overflow: auto;

    .hist_item {
      padding: 5px;
      background: #4b4b4b;
      display: flex;
      flex-direction: column;
      margin-top: 5px;
      margin-bottom: 5px;
      border-radius: 5px;
    }

    .hist_item:hover {
      background: #6b6b6b;
    }
  }
}


