.wrapper {
  height: 90vh;
  width: 1000px;
  color: black;
  background-color: #7e7e7e;
  padding: 15px;
  border-radius: 10px;


  display: flex;
  flex-direction: row;
  gap: 10px;

  .options {
    background-color: #4b4b4b;
    border-radius: 10px;
    padding: 5px;
    height: 100%;
    width: 40%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    overflow: auto;

    .item {
      font-weight: 500;
      background-color: #c2c2c2;
      border-radius: 5px;
      padding: 5px;
    }

    .item:hover {
      background-color: #dedede;
      cursor: pointer;
    }
  }

  .variant_control {
    border-radius: 10px;
    padding: 5px;
    height: 100%;
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;

    .null {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      font-size: 50px;
      height: 100%;
    }

    .variants {
      background-color: #4b4b4b;
      height: 60%;
      border: 1px black solid;
      border-radius: 10px;
      padding: 5px;

      display: flex;
      flex-direction: column;
      gap: 5px;

      overflow: auto;

      .variant {
        background-color: #333333;
        color: #d9d9d9;
        border-radius: 5px;
        padding: 5px;
        font-weight: 500;
      }

      .variant:hover {
        color: white;
        cursor: pointer;
        background-color: #181818;
      }
    }

    .addvariant {
      background-color: #4b4b4b;
      height: 20%;
      border: 1px black solid;
      border-radius: 10px;
      padding: 5px;
      display: flex;
      flex-direction: column;
      gap: 10px;

      overflow: auto;
    }

    .addoption {
      display: flex;
      flex-direction: column;
      gap: 10px;

      height: 20%;
      border: 1px black solid;
      border-radius: 10px;
      padding: 5px;

      overflow: auto;
    }
  }
}