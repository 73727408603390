@import '../../../app/styles/variables/variables';
@import '../../../app/styles/variables/mixins';

// инпут
.inputWrapper {
    @include flexBetween;
    width: 100%;
    border: 1px solid white;
    border-radius: $extraSmallBorderRadius;
    opacity: 0.7;
}
.inputWrapper:hover {
    opacity: 1;
}
.inputWrapper:focus-visible {

}
.inputWrapperBlack {
    border: 1px solid black;
}

.input {

}
.input:focus {

}
.inputMainBox {
    width: 100%;
}

// дефолтный инпут
.defaultCustomSearchInput {
    width: 100%;
    height: 100%;
    background-color: transparent;
    border-radius: $extraSmallBorderRadius;
    border: none;
    padding: 15px 10px;
    font-size: 16px;
}

// инпут белого цвета
.customSearchInput {
    composes: defaultCustomSearchInput;
    color: white;
}
.customSearchInput::placeholder {
    color: white;
    opacity: 0.7;
}
.customSearchInput:focus-visible {
    border: none;
    outline: none;
}

// инпут чёрного цвета
.customSearchInputBlack {
    composes: defaultCustomSearchInput;
    color: black;
}
.customSearchInputBlack::placeholder {
    color: black;
    opacity: 0.7;
}
.customSearchInputBlack:focus-visible {
    border: none;
    outline: none;
}

// кнопка
.button {
    background: transparent url("/../src/shared/assets/workspace/cancel-icon-white.svg") no-repeat center;
    cursor: pointer;
}
.glassIcon {
    padding-left: 10px;

    img {
        width: 30px;
        height: 30px;
    }
}
.clearButton {
    background: transparent;
    border: none;
    padding-right: 15px;

    img {
        width: 20px;
        height: 20px;
    }
}

// стили для ошибок
.errorWrapper {
    padding-top: 5px;
    padding-left: 10px;
    font-size: 14px;
    color: red;
}
.errorHelperText {
    composes: errorWrapper;
}
.error {
    color: red;
    height: 20px;
}
.error:focus-visible {

}
.errorInput {
    border: 1px solid red;
    outline: none;
}
.errorInput:focus-visible {
    border: 1px solid red;
}