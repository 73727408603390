/*SPINNER*/
.ldsSpinner {
    color: var(--primary-color);
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.ldsSpinner div {
    transform-origin: 40px 40px;
    animation: ldsSpinner 1.2s linear infinite;
}
.ldsSpinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 37px;
    width: 6px;
    height: 18px;
    border-radius: 20%;
    background: var(--primary-color);
}
.ldsSpinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
}
.ldsSpinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
}
.ldsSpinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
}
.ldsSpinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
}
.ldsSpinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
}
.ldsSpinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
}
.ldsSpinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
}
.ldsSpinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
}
.ldsSpinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
}
.ldsSpinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
}
.ldsSpinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
}
.ldsSpinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
}
@keyframes ldsSpinner {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}


/*LINEAR*/
.loader {
    width: 32px;
    height: 32px;
    position: relative;
    border-radius: 50%;
    color: var(--primary-color);
    animation: fill 1s ease-in infinite alternate;
}
.loader::before , .loader::after {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    left: 48px;
    top: 0;
    animation: fill 0.9s ease-in infinite alternate;
}

.loader::after {
    left: auto;
    right: 48px;
    animation-duration: 1.1s;
}

@keyframes fill {
    0% {  box-shadow: 0 0 0 2px inset }
    100%{ box-shadow: 0 0 0 10px inset }
}

/*circle*/

.circle {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: inline-block;
    border-top: 3px solid var(--primary-color);
    border-right: 3px solid transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/*ellipsis*/

.ldsEllipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.ldsEllipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: var(--primary-color);
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.ldsEllipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
}
.ldsEllipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
}
.ldsEllipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
}
.ldsEllipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}
@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(24px, 0);
    }
}
