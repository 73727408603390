@import '../../../app/styles/variables/mixins';
@import '../../../app/styles/variables/variables';

.encashmentArchiveModal_mainBlock {
    @include defaultModalStyle;
    width: 700px;

    @media screen and (max-width: 768px) {
        width: 90vw;
        max-height: 500px;
        overflow-y: scroll;
    }

    .encashmentArchiveModal_title {
        @include modalTitle;
    }

    .encashmentArchiveModal_list {
        @include divLargeMarginBottom;
        max-height: 550px;
        overflow: auto;
        padding-right: 10px;

        .supplyInvoiceArchiveModal_item {
            @include flexOnlyBetween;
            background-color: $themeColorGray;
            border: 1px solid $themeColorMilk;
            border-radius: $smallBorderRadius;
            padding: $defaultSmallMargin;
            @include hoverCursorPointer;

            @media screen and (max-width: 670px) {
                font-size: 2.4vw;
            }
            
            @media screen and (max-width: 640px) {
                justify-content: flex-start;
            }

            .status_WaitingPayment {
                @include supplyInvoiceStatus($bgColorProfileStatusWaitingPayment);
            }

            .status_Ready {
                @include supplyInvoiceStatus($bgColorProfileStatusReady);
            }

            .status_Canceled {
                @include supplyInvoiceStatus($bgColorProfileStatusCanceled);
            }

            .item_content {
                @include flexBetween;

                @media screen and (max-width: 640px) {
                    justify-content: flex-start;
                    align-items: flex-start;
                    flex-direction: column;
                    width: 100%;
                    gap: 1.4vw;
                }

                .content_info {
                    height: 100%;
                    width: 250px;
                    border-right: 2px solid $themeColorMilk;
                    @include flexColumnOnly;
                    justify-content: space-between;

                    @media screen and (max-width: 670px) {
                        justify-content: center;
                        border-bottom: 1px solid $themeColorMilk;
                        border-right: none;
                    }

                    @media screen and (max-width: 640px) {
                        width: 100%;
                        padding: 0 0 2vw 0;
                    }

                    @media screen and (max-width: 440px) {
                        width: 61vw;
                    }

                    > div:not(:last-child) {
                        font-size: 20px;

                        @media screen and (max-width: 670px) {
                            font-size: 3vw;
                        }
                    }
                }

                @include modalDateBlock;
            }
        }
    }
}