@import '../../../app/styles/variables/mixins';
@import '../../../app/styles/variables/variables';

.updateWorkModal_mainBox {
    @include defaultModalStyle;

    .updateWorkModal_inputs {
        margin-bottom: $defaultLargeMargin;
        @include divSmallMarginBottom;
    }
    .updateWorkModal_buttons {
        @include workAndProductModalsButtonBlock;
    }
}