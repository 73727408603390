.wrapper {
  height: 600px;
  width: 600px;
  background: #4b4b4b;
  padding: 10px;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  gap: 5px;

  overflow: auto;

  .item {
    border: #000000 solid 1px;
    border-radius: 5px;
    padding: 10px;

    .data {
      color: white;
      padding: 5px;
      display: flex;
      gap: 5px;

      .title {

      }

      .value {
        font-weight: 500;
      }
    }
  }

}