@import '../../../app/styles/variables/mixins';
@import '../../../app/styles/variables/variables';

.wrapper {
  @include cardCatalogStl;
  height: 1vw;
  width: 100%;
  display: flex;
  justify-content: start;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }

  .trees {
    display: flex;
    gap: 15px;

    @media screen and (max-width: 1024px) {
      flex-direction: column;
    }

    .filters {
      background-color: $themeColorDark;
      border-radius: 25px;

      @media screen and (max-width: 1024px) {
        height: 30%;
      }
    }

    .categories {
      max-width: 400px;
      overflow: auto;
      background-color: $themeColorDark;
      border-radius: 25px;

      @media screen and (max-width: 1024px) {
        height: 70%;
      }

      @media screen and (max-width: 768px) {
        width: 100%;
        max-width: 768px;
      }
    }
  }

  .table {
    width: 100%;
    height: 100%;
    background-color: $themeColorDark;
    padding: 15px;
    border-radius: 30px;

    @media screen and (max-width: 768px) {
      min-height: 800px;
    }

    @media screen and (max-width: 768px) {
      min-height: 1100px;
    }


    .table_header {
      color: black;
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
      gap: 15px;

      @media screen and (max-width: 768px) {
        flex-direction: column;
      }

      .table_header_search {
        width: 40%;
        @media screen and (max-width: 768px) {
          width: 100%;
        }
      }

      .table_header_storage {
        width: 25%;

        @media screen and (max-width: 768px) {
          width: 100%;
        }
      }

      .table_header_sort {
        width: 25%;

        @media screen and (max-width: 768px) {
          width: 100%;
        }
      }
    }

    .table_data {
      height: 80%;
      border-radius: 25px;
      overflow: hidden;
    }

    .table_pag {
      height: 7.5%;
      border-radius: 5px;
      margin: 5px;
      display: flex;
      justify-content: center;
      flex-direction: row;
      padding: 5px;
      border: 1px white solid;
      margin-top: 10px;

      @media screen and (max-width: 768px) {
        height: 3.5%;
      }

      .pag_item {
        cursor: pointer;
        user-select: none;
        border-radius: 5px;
        width: 40px;
        text-align: center;
        border: 1px white solid;
        padding: 2px;
        margin: 2px;
        overflow: hidden;
      }

      .pag_item:hover {
        background-color: #1a1a1a;
      }
    }
  }
}