@import '../../app/styles/variables/mixins';
@import '../../app/styles/variables/variables';

.employeeSalaryModal_mainBlock {
    @include defaultModalStyle;
    gap: $defaultLargeMargin;
    width: 600px;

    @media screen and (max-width: 768px) {
        max-height: 500px;
        overflow-y: scroll;
    }

    button {
        margin-bottom: 2vw;
    }

    @media screen and (max-width: 640px) {
        width: 95vw;
        max-height: 600px;
        overflow-y: scroll;
    }

    .employeeSalaryModal_title {
        @include modalTitle;
    }

    .employeeSalaryModal_content {
        @include flexOnlyBetween;
        gap: $defaultLargeMargin;

        @media screen and (max-width: 640px) {
            flex-direction: column;
        }

        .content_scrollWrapper {
            width: 50%;

            @media screen and (max-width: 640px) {
                width: 100%;
            }

            .content_employeeList {
                background-color: $themeColorMilk;
                padding: $defaultLargeMargin;
                border-radius: $smallBorderRadius;
                color: black;
                @include divExtraSmallMarginBottom;
                height: 312px;
                overflow-y: auto;

                .employeeList_item {
                    padding: 5px;

                    &:hover {
                        cursor: pointer;
                        background-color: $themeColorDark;
                        color: white;
                        padding: 5px;
                    }
                }

                .employeeList_item_active {
                    background-color: $themeColorDark;
                    color: white;
                    padding: 5px;
                }
            }
        }
        .content_employeeData {
            width: 50%;
            
            @media screen and (max-width: 640px) {
                width: 100%;
            }

            .employeeData_inputs {
                > div {
                    margin-bottom: $defaultSmallMargin;
                }

                .employeeData_inputsItem {
                    @include flexBetween;
                    gap: $defaultSmallMargin;

                    @media screen and (max-width: 640px) {
                        font-size: 2.8vw;
                    }

                    @media screen and (max-width: 440px) {
                        font-size: 3.8vw;
                    }

                    input {
                        width: 100px;
                    }
                }

                .inputs_cancelBtn {
                    margin-bottom: 2.4vw;

                    button {
                        @include defaultMediumButton;
                    }
                }
            }

            .employeeData_saveBtn {
                button {
                    padding: 20px;
                    width: 100%;
                }
            }
        }
    }
}