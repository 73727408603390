@import '../../../app/styles/variables/mixins';
@import '../../../app/styles/variables/variables';

.cashboxMainBlock {
    @include wrapperMainBlock;
    @include flexOnlyBetween;
    gap: $defaultLargeMargin;

    @media screen and (max-width: 1280px) {
        flex-direction: column;
        justify-content: flex-start;
    }

    .cashboxMainBlock_leftSideWrapper {
        width: 30%;
        @include flexColumn;

        @media screen and (max-width: 1280px) {
            width: 100%;
        }

        .leftSide_tables {
            background-color: $themeColorDark;
            border-radius: $largeBorderRadius;
            display: flex;
            justify-content: space-evenly;
            flex-wrap: wrap;
            min-width: 100%;
            padding: 20px;
            gap: 20px;
            flex-direction: column;

            button {
                padding: 10px 40px;
                height: 55px;
            }
        }

        .leftSide_client {
            background-color: $themeColorDark;
            border-radius: $largeBorderRadius;
            margin: $defaultLargeMargin 0;
            padding: $defaultLargeMargin;
            @include flexColumn;
            justify-content: center;
            width: 100%;
            height: 41%;

            .leftSide_client_buttons {
                @include flexOnlyBetween;
                flex-wrap: wrap;
                margin: $defaultLargeMargin 0 0 0;
                width: 100%;

                .client_buttons_choose {
                    button {
                        min-width: 300px;
                        padding: $largeButtonPadding;
                    }
                }

                .client_buttons_cancel {
                    button {
                        width: 50px;
                        height: 50px;
                        padding: 0;
                        font-size: 20px;
                    }
                }
            }
        }

        .leftSide_discount {
            @include flexColumn;
            justify-content: space-between;
            background-color: $themeColorDark;
            border-radius: $largeBorderRadius;
            padding: $largeButtonPadding;
            width: 100%;
            height: 40%;

            .discount_background {
                background-color: $themeColorGray;
                border-radius: $largeBorderRadius;
                margin-bottom: $defaultLargeMargin;
                padding: $defaultLargeMargin;
                @include widthHeightFull;

                .discount_info {
                    @include flexColumn;

                    .info_title {
                        margin-bottom: $defaultSmallMargin;
                        font-weight: bold;
                    }

                    .info_name {
                        @include flexCenter;
                        margin-bottom: $defaultLargeMargin;
                        border: 2px solid #C0C0C0;
                        border-radius: 10px;
                        padding: 10px;
                        width: 100%;
                    }

                    .info_types {
                        @include flexBetween;
                        width: 100%;

                        .info_type {
                            @include defaultFiledStyle;
                            width: 30%;
                        }

                        .info_value {
                            @include defaultFiledStyle;
                            width: 65%;
                        }
                    }
                }
            }

            .discount_buttons {
                @include flexOnlyBetween;
                flex-wrap: wrap;
                margin: 0 $defaultLargeMargin;
                width: 100%;

                .buttons_choose {
                    button {
                        min-width: 300px;
                        padding: $largeButtonPadding;

                    }
                }

                .buttons_cancel {
                    button {
                        width: 50px;
                        height: 50px;
                        padding: 0;
                        font-size: 20px;
                    }
                }
            }
        }
    }

    .cashboxMainBlock_rightSideWrapper {
        @include flexOnlyBetween;
        flex-direction: column;
        background-color: $themeColorGray;
        border-radius: $largeBorderRadius;
        padding: $defaultLargeMargin;
        width: 70%;

        @media screen and (max-width: 1280px) {
            width: 100%;
        }

        .cashboxMainBlock_rightSideHeader {
            @include flexOnlyBetween;
            margin-bottom: $defaultLargeMargin;
            gap: 20px;

            .header_chooseBtn {
                button {
                    min-width: 200px;
                }
            }
            .header_searchSelect {
                width: 100%;
            }
        }

        .cashboxMainBlock_rightSideMiddle {
            font-size: 20px;
            height: 100%;
            background-color: $themeColorDark;
            border-radius: $largeBorderRadius;
            margin-bottom: $defaultLargeMargin;
        }

        .cashboxMainBlock_rightSideBottom {

            .rightSideBottom_buttonsBlock {
                @include flexOnlyBetween;

                @media screen and (max-width: 640px) {
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 4vw;
                }

                .buttonsBlock_one {
                    @include flexBetween;

                    > div {
                        margin-right: $defaultLargeMargin;
                    }

                    .one_cancelBtn {
                        button {
                            width: 40px;
                            height: 40px;
                            padding: 5px;
                            font-size: 20px;
                        }
                    }

                    .one_noDiscount {
                        @include defaultFiledStyle;
                    }

                    .one_discount {
                        @include defaultFiledStyle;
                    }
                }

                .buttonsBlock_two {
                    @include defaultFiledStyle;
                    width: 150px;
                }

                .rightSideBottom_payBlock {
                    display: flex;
                    justify-content: flex-end;

                    button {
                        padding: 10px 20px;
                        width: 150px;
                        height: 40px;
                    }
                }
            }
        }
    }
}