html, body {
    width: 100%;
    height: 100%;
    margin: 0;
    line-height: 1;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    overflow-x: hidden; /*чтобы не было белой полосы сбоку на мобилках*/
    -ms-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

*,*:before,*:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
    //font-size: 16px;
}
:focus,:active {outline: none;}
a:focus,a:active {outline: none;}

nav,footer,header,aside {display: block;}

input,button,textarea {font-family:inherit;}

input::-ms-clear {display: none;}
button {cursor: pointer;}
button::-moz-focus-inner {padding:0;border:0;}

ul li {list-style: none;}
img {vertical-align: top;}

//h1,h2,h3,h4,h5,h6{font-size:inherit;font-weight: 400;}
