@import '../../../app/styles/variables/mixins';
@import '../../../app/styles/variables/variables';

.updateProductModal_mainBox {
    @include defaultModalStyle;

    .updateProductModal_inputs {
        margin-bottom: $defaultLargeMargin;
        @include divSmallMarginBottom;
    }
    .updateProductModal_buttons {
        display: flex;
        gap: 20px;

        button {
            height: 50px;
            width: 100%;
        }

        > div {
            width: 100%;
        }
    }
}