.table {
  display: flex;
  flex-direction: column;
  gap: 5px;
  overflow: auto;
  height: 100%;

  .row {
    color: black;
    padding: 5px;
    border-radius: 10px;
    background-color: #d9d9d9;
    min-height: 64px;
    max-height: 64px;

    display: flex;
    justify-content: start;
    gap: 20px;

    .info {
      height: 100%;
      display: flex;
      flex-direction: column;
      max-width: 300px;

      .label {
        padding-left: 10px;
        color: #4b4b4b;
      }

      .name {
        font-size: 18px;
        font-weight: 600;
        flex-grow: 1;
        overflow: auto;
        word-wrap: normal;
      }
    }

    .box {
      padding: 5px;
      color: #d9d9d9;
      border-radius: 10px;
      background-color: #7a7a7a;
      justify-content: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2px;

      .label {
        color: #c0c0c0;
        font-size: 10px;
      }

      .value {
        color: black;
        font-weight: 600;
        font-size: 18px;
      }

    }

    .inter {
      flex-grow: 1;
      padding: 3px;
      background-color: #7a7a7a;
      border-radius: 10px;
    }

  }

  .row:hover {
    background-color: #b7b7b7;
  }
}