@import '../../../app/styles/variables/mixins';
@import '../../../app/styles/variables/variables';

.shopStorageModal_mainBlock {
  @include defaultModalStyle;
  @include flexOnlyBetween;
  gap: $defaultLargeMargin;
  min-width: 700px;

  @media screen and (max-width: 768px) {
    min-width: auto;
    width: 91vw;
  }

  @media screen and (max-width: 475px) {
    flex-direction: column;
    max-height: 500px;
    overflow-y: scroll;
  }

  @include createShopStorageModal(370px, 50%, 50%);
}