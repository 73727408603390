@import '../../../app/styles/variables/mixins';
@import '../../../app/styles/variables/variables';

.updateWorkGroupModal_mainBox {
    @include defaultModalStyle;

    .updateWorkGroupModal_inputs {
        margin-bottom: $defaultLargeMargin;
        @include divLargeMarginBottom;
    }

    .createWorkModal_buttons {
        @include workAndProductModalsButtonBlock;
    }
}