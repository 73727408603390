@import '../../../app/styles/variables/mixins';
@import '../../../app/styles/variables/variables';

.wrapper {
  @include wrapperMainBlock;
  height: 1vw;
  overflow: hidden;

  .table {
    overflow: auto;
    padding: 20px;
    height: 100%;

    background-color: #4b4b4b;
    border-radius: 25px;
    width: 80%;
  }

  .table_pag {

  }
}