.table {
  display: flex;
  flex-direction: column;
  gap: 5px;
  overflow: auto;
  height: 94%;
  border: 3px black solid;
  border-radius: 10px;
  padding: 5px;

  .row {
    color: black;
    padding: 5px;
    border-radius: 10px;
    background-color: #d9d9d9;
    min-height: 64px;
    max-height: 64px;

    display: flex;
    justify-content: start;
    gap: 20px;

    .info {
      height: 100%;
      display: flex;
      flex-direction: column;
      width: 20%;

      .label {
        padding-left: 10px;
        color: #4b4b4b;
      }

      .name {
        font-size: 18px;
        font-weight: 600;
        flex-grow: 1;
        overflow: auto;
        word-wrap: normal;
      }
    }

    .box {
      padding: 5px;
      color: #d9d9d9;
      border-radius: 10px;
      background-color: #7a7a7a;
      justify-content: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2px;

      .label {
        color: #c0c0c0;
        font-size: 10px;
      }

      .value {
        color: black;
        font-weight: 600;
        font-size: 18px;
      }

    }

    .inter {
      flex-grow: 20;
      padding: 3px;
      background-color: #7a7a7a;
      border-radius: 10px;

      gap: 5px;
      display: flex;
      overflow: auto;

      .inter_item {
        padding: 5px;
        color: #dedede;
        border-radius: 10px;
        background-color: #383838;

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }

      .inter_item:hover {
        background-color: #131313;

      }
    }
  }

  .row:hover {
    background-color: #b2b2b2;

  }
}