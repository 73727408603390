.table {
  padding: 5px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  overflow: auto;
  height: 94%;
  border: 3px black solid;
  border-radius: 10px;

  .row {
    color: black;
    padding: 5px;
    border-radius: 10px;
    background-color: #d9d9d9;
    min-height: 64px;
    max-height: 70px;

    display: flex;
    justify-content: start;
    gap: 20px;

    @media screen and (max-width: 440px) {
      min-height: 104px;
    }

    .info {
      height: 100%;
      display: flex;
      flex-direction: column;
      width: 45%;

      .label {
        padding-left: 10px;
        color: #4b4b4b;
      }

      .name {
        font-size: 18px;
        font-weight: 600;
        flex-grow: 1;
        overflow: auto;
        word-wrap: normal;
      }
    }
  }

  .row:hover {
    background-color: #b2b2b2;
  }
}