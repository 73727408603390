@import '../../../app/styles/variables/mixins';
@import '../../../app/styles/variables/variables';

.wrapper {
  @include wrapperMainBlock;
  overflow: auto;

  .header {
    border: 1px black solid;
    display: flex;
    justify-content: space-around;
    margin-bottom: 10px;

    .page_button {
      background-color: #4b4b4b;
      font-size: 24px;
      font-weight: 600;
      text-align: center;
      width: 300px;
      padding: 15px;
      border-radius: 10px;
    }

    .page_button:hover {
      background-color: #6e6e6e;
    }
  }

  .page1 {
    border: 1px black solid;
    height: 100%;
    padding: 5px;
  }

  .page2 {
    border: 1px black solid;
    height: 100%;
    padding: 5px;

    .gran {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;

      .gran_select {
        color: black;
        width: 800px;
      }
    }

    .gran_desc {
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      gap: 10px;
      padding: 5px;
      border: 2px black solid;
      border-radius: 10px;
    }

    .link {
      gap: 10px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;

    }

    .page_code {
      height: 620px;
      overflow: auto;
    }
  }

  .page3 {
    border: 1px black solid;
    //height: 1000px;
    padding: 5px;

  }
}