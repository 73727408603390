.wrapper {
  width: 100%;
  background-color: #4b4b4b;
  border-radius: 10px;
  padding: 5px;
  display: flex;
  gap: 5px;
  flex-direction: column;

  height: 78px;

  .fio {
    text-align: center;
    color: #ececec;
    font-size: 18px;
    font-weight: 600;
    word-wrap: break-word;
  }

  .info {
    display: flex;
    justify-content: space-between;
    gap: 10px;

    .box {
      padding: 5px;
      color: white;
      background-color: #919191;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .label {

      }
    }
  }

}