@import '../../../app/styles/variables/mixins';
@import '../../../app/styles/variables/variables';

.fullEditor {
  @include defaultBlockStyles($themeColorMilk);

  .descriptionEditor_title {
    font-weight: bold;
    font-size: 18px;
    color: black;
    text-align: center;
    margin-bottom: $defaultSmallMargin;
  }

  .descriptionEditor_editorTextarea {
    color: black;
    height: 100%;

  }
}