.wrapper {
  height: 650px;
  width: 500px;
  color: black;
  background-color: #D9D9D9;
  padding: 15px;
  border-radius: 10px;
  overflow-y: scroll;

  @media screen and (max-width: 768px) {
    height: 550px;
    max-height: 550px;
    width: 90vw;
    flex-direction: column;
    height: 780px;
  }

  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 30px;

  .header {
    width: 100%;
    height: 80px;
    border-bottom: 2px black solid;

    .header_row1 {
      padding: 5px;
      display: flex;
      justify-content: space-between;
      gap: 10px;

      .header_row1_number {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        font-size: 20px;
        flex-grow: 1;
      }

      .header_row1_status {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-grow: 4;
        padding: 5px;
        border-radius: 5px;
        font-weight: 600;
      }

      .header_row1_payment {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-grow: 2;
        color: white;
        padding: 5px;
        border-radius: 5px;
        font-weight: 600;
      }
    }

    .header_row2 {
      padding: 5px;
      display: flex;
      justify-content: space-between;
      gap: 10px;

      .header_row2_data {
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #4b4b4b;
        border-radius: 5px;
        flex-grow: 1;
      }

      .header_row2_button {
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #2aa25a;
        border-radius: 5px;
        flex-grow: 2;
      }

      .header_row2_button:hover {
        cursor: pointer;
        background-color: #21884b;
      }
    }
  }

  .pageselect {
    display: flex;
    justify-content: space-around;
    gap: 10px;

    .variant {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      padding: 5px;
      font-weight: 500;
      width: 100%;
    }

    .variant:hover {
      background-color: #c9c9c9;
      cursor: pointer;
    }
  }

  .page1 {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background-color: #A5A5A5;
    height: 100%;

    @media screen and (max-width: 768px) {
      gap: 20px;
      overflow-y: scroll;
      padding-bottom: 10px;
    }

    .client {
      display: flex;
      flex-direction: column;
      height: 165px;

      @media screen and (max-width: 768px) {
        height: 500px;
      }

      .name {
        display: flex;
        padding: 10px;
        font-weight: 500;
        font-size: 18px;
        align-items: center;
        justify-content: center;
      }

      .info {
        display: flex;
        justify-content: space-around;

        @media screen and (max-width: 768px) {
          flex-wrap: wrap;
        }

        .balance {

        }

        .phone {
          font-weight: 500;
          font-size: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .description {
        margin-right: 5px;
        margin-left: 5px;
        height: 80px;

        @media screen and (max-width: 768px) {
          height: 200px;
        }

        .desc_label {
          font-size: 12px;
          padding-right: 20px;
          text-align: right;
        }

        .desc_value {
          height: 100%;
          overflow: auto;
          border-radius: 10px;
          padding: 10px;
          color: white;
          background-color: #4b4b4b;
        }
      }
    }

    .manager_select {
      padding: 5px;
    }

    .crm {
      margin: 5px;
      //border: 1px black solid;
      flex-grow: 1;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 1000;
      font-size: 36px;
      height: 200px;
    }
  }

  .page2 {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background-color: #A5A5A5;
    height: 100%;
    gap: 5px;
    padding: 5px;

    .table {
      height: 100%;
      width: 100%;
    }

    .btns {
      display: flex;
      justify-content: end;
    }
  }

  .page3 {
    align-items: center;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background-color: #A5A5A5;
    height: 100%;
    gap: 5px;

    .delivery_select_wrapper {
      width: 100%;

      .delivery_select {
        width: 100%;
        padding: 5px;
      }
    }

    .butn {
      height: 40px;
    }

    .delivery_table {

      width: 98%;
      border-radius: 5px;
      margin: 5px;
      background-color: #6e6e6e;
      height: 250px;

      .delivery_table_select {
        padding: 5px;
        display: flex;
        justify-content: space-around;
        gap: 5px;
        align-items: center;

        .sel {
          flex-grow: 1;
        }

        .butt {
          height: 30px;
          width: 30px;
          border-radius: 5px;
        }
      }

      .delivery_table_values {
        padding: 5px;
        overflow: auto;
        height: 72%;
        display: flex;
        flex-direction: column;
        gap: 5px;

        .delivery_table_values_value {
          display: flex;
          justify-content: space-between;
          gap: 5px;
          border-radius: 5px;
          padding: 5px;
          background-color: #4b4b4b;
          color: white;
          align-items: center;

          .name {
            display: flex;
            align-items: center;
          }

          .spn {
            width: 100%;
            overflow: hidden;
          }

          .spn2 {
            text-align: right;
            width: 100%;
          }
        }
      }
    }
  }
}