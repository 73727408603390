@import '../../../app/styles/variables/mixins';
@import '../../../app/styles/variables/variables';

.container {
    @include wrapperMainBlock;
    gap: $defaultLargeMargin;
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-areas: "categories tableBox";

    @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
    }

    .categories, .tableBox {
        padding: $defaultLargeMargin;
        background-color: $themeColorDark;
        border-radius: $largeBorderRadius;
    }

    .categories {
        grid-area: categories;
        overflow: auto;

        @media screen and (max-width: 1280px) {
            font-size: 1.3vw;
        }

        @media screen and (max-width: 768px) {
            font-size: 12px;
        }
    }

    .tableBox {
        grid-area: tableBox;
        padding: $defaultLargeMargin;
        background-color: $themeColorDark;
        height: 100%;
    }
}



