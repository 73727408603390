.shopLoader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 60vh;

    img {
        width: 200px;
        height: auto;

    }
}