@import '../../../app/styles/variables/mixins';
@import '../../../app/styles/variables/variables';

.shopStorageModal_mainBlock {
  @include defaultModalStyle;
  @include flexOnlyBetween;
  gap: $defaultLargeMargin;
  min-width: 900px;

  @media screen and (max-width: 860px) {
    width: 95vw;
    min-width: auto;
    flex-direction: column;
    max-height: 500px;
    overflow-y: scroll;
  }

  > div {
    &:nth-child(2) {
      min-height: 300px;
    }
  }

  .shopStorageModal_tree {
    background-color: $themeColorMilk;
    padding: $defaultLargeMargin;
    border-radius: $smallBorderRadius;
    width: 30%;
    color: black;

    display: flex;
    flex-direction: column;
    gap: 10px;

    @media screen and (max-width: 860px) {
      width: 100%;
    }

    .groups {
      flex-grow: 1;

      display: flex;
      justify-content: start;
      flex-direction: column;
      gap: 5px;

      .groupItem {
        display: flex;
        height: 40px;
        padding: 5px;
        align-items: center;
        justify-content: start;
        color: white;
        border-radius: 10px;
        background-color: #4b4b4b;
      }

      .groupItem:hover {
        background-color: #363636;
      }
    }

    .buttons {
      color: black;
      display: flex;
    }
  }

  @include createShopStorageModal(550px, 35%, 35%);
}