@import '../../app/styles/variables/mixins';
@import '../../app/styles/variables/variables.scss';

.searchPage_container {
  background-color: $themeColorGray;
  @include flexCenter;

  .searchForm_mainBox {
    background-color: $themeColorDark;
    border-radius: $smallBorderRadius;
    width: 500px;
    min-height: 370px;
    max-height: 430px;
    @include flexColumn;
    padding: 20px 40px 40px 40px;

    @media screen and (max-width: 440px) {
      width: 340px;
    }

    .select {
      margin-top: 10px;
      width: 100%;
    }

    .haveAccount {
      margin-top: 10px;
      margin-bottom: 20px;

      &:hover {
        cursor: pointer;
        color: #1876D1;
        opacity: 1;
      }
    }
  }
}


.searchForm_form {
  padding: 20px 10px;

  .text {
    text-align: center;
    color: white;
    font-size: larger;
    margin-top: 30px;
    margin-bottom: 10px;
  }
}

.searchInput {
  margin-bottom: 10px;
}

.create_buttons {
  @include flexOnlyBetween;
  gap: $defaultLargeMargin;
  margin-bottom: $defaultLargeMargin;
  width: 280px;
  height: 50px;

  > div {
    width: 100%;
  }

  button {
    opacity: 0.7;

    &:hover {
      opacity: 1;
    }
  }

  .create_submitButton button {
    background-color: green;
    width: 160px;
  }

  .cancel_Button button {
    background-color: transparent;
    width: 100%;
  }
}
