@import '../../../app/styles/variables/mixins';
@import '../../../app/styles/variables/variables';

.imageGallery {
  @include defaultBlockStyles($themeColorMilk);
  @include flexColumnOnly;
  justify-content: space-between;

  .imageGallery_imageList {
    user-select: none;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
    max-height: 290px;
    overflow-y: auto;

    .emptyList {
      overflow: hidden;
    }

    .imageList_item {
      position: relative;

      &:hover {
        cursor: pointer;
        opacity: 0.5;
      }

      img {
        width: 100px;
        height: 100px;
        object-fit: cover;
      }

      .active_image {
        opacity: 0.5;
        border: 1px solid white;
      }

      .imageList_imageCount {
        position: absolute;
        top: 0;
        left: 0;
        color: white;
        margin: 5px;
        padding: 2px 5px;
        background-color: $themeColorDark;
        border-radius: 5px;
      }

      .imageList_imageCount:hover {
        border: 3px solid black;
      }

      .imageList_deleteItem {
        position: absolute;
        top: 0;
        right: 0;
        margin: 5px;
        width: 20px;
        height: 20px;

        &:hover {
          cursor: pointer;
          filter: contrast(3);
        }
      }
    }
  }

  .imageGallery_buttons {
    .imageGallery_sortButtons {
      margin-top: $defaultLargeMargin;
      @include flexOnlyBetween;
      gap: $defaultLargeMargin;
    }

    .imageGallery_addImage {
      margin-top: $defaultLargeMargin;
      @include inputTypeFile($themeColorGray);
      color: black;
    }
  }
}