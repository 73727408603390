@import '../../../app/styles/variables/mixins';
@import '../../../app/styles/variables/variables';

.encashmentArchiveModal_mainBlock {
    @include defaultModalStyle;
    width: 850px;

    @media screen and (max-width: 940px) {
        width: 92vw;
    }

    @media screen and (max-width: 768px) {
        max-height: 500px;
        overflow-y: scroll;
    }

    .newInventory_btn {
        margin-bottom: 10px;
        text-align: center;
    }

    .header_title {
        @include flexOnlyBetween;
        gap: 20px;

        @media screen and (max-width: 768px) {
            margin: 2vw 0 0 0;
        }

        @media screen and (max-width: 440px) {
            align-items: center;
            margin: 3vw 0 0 0;
        }

        > div {
            @include modalTitle;

            @media screen and (max-width: 768px) {
               font-size: 3vw;
            }
        }
        .encashmentArchiveModal_title {
            width: 60%;
        }
        .shortFall_title {
            width: 60%;
        }
    }

    .scroll_wrapper {
        @include flexOnlyBetween;
        gap: 20px;

        max-height: 550px;
        overflow: auto;
        padding-right: 10px;

        @media screen and (max-width: 440px) {
            max-height: 500px;
        }

        .encashment_Block {
            width: 100%;

            .encashmentArchiveModal_title {
                @include modalTitle;
            }

            .encashmentArchiveModal_list {
                //@include divLargeMarginBottom;
                @include SupplyInvoiceArchiveModal(50%);

                @include flexBetween;
                gap: 10px;
                overflow: hidden;
                flex-wrap: wrap;

                @media screen and (max-width: 768px) {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                }

                .shortFall_Block {
                    width: 48%;

                    @media screen and (max-width: 768px) {
                        width: 100%;
                    }

                    .shortFall_title {
                        @include modalTitle;
                    }

                    .shortFall_list {
                        @include divLargeMarginBottom;

                        .shortFall_listItem {
                            background-color: $themeColorGray;
                            border: 1px solid $themeColorMilk;
                            border-radius: $smallBorderRadius;
                            padding: $defaultSmallMargin;
                            height: 100%;

                            @media screen and (max-width: 440px) {
                                height: 113px;
                            }

                            .listItem_title {
                                font-weight: bold;
                                font-size: 20px;
                                margin-bottom: $defaultSmallMargin;

                                @media screen and (max-width: 640px) {
                                    font-size: 3.2vw;
                                }
                            }

                            .listItem_content {
                                @include flexBetween;

                                @media screen and (max-width: 640px) {
                                    font-size: 2.2vw;
                                }

                                .content_info {
                                    line-height: 1.3;
                                }

                                .content_date {
                                    @include divSmallMarginBottom;
                                    line-height: $minLineHeight;
                                    border-left: 2px solid $themeColorMilk;
                                    padding-left: 10px;
                                }
                            }
                        }
                    }
                }
                .no_shortFall {
                    width: 48%;
                    height: 139px;
                    background-color: $themeColorGray;
                    border: 1px solid $themeColorMilk;
                    border-radius: $smallBorderRadius;

                    @media screen and (max-width: 768px) {
                        width: 100%;
                        height: 113px;
                    }
                }
            }
        }

        //.shortFall_Block {
        //    width: 40%;
        //
        //    .shortFall_title {
        //        @include modalTitle;
        //    }
        //
        //    .shortFall_list {
        //        @include divLargeMarginBottom;
        //
        //        .shortFall_listItem {
        //            background-color: $themeColorGray;
        //            border: 1px solid $themeColorMilk;
        //            border-radius: $smallBorderRadius;
        //            padding: $defaultSmallMargin;
        //            height: 139px;
        //
        //            .listItem_title {
        //                font-weight: bold;
        //                font-size: 20px;
        //                margin-bottom: $defaultSmallMargin;
        //            }
        //
        //            .listItem_content {
        //                @include flexBetween;
        //
        //                .content_info {
        //                    line-height: 1.3;
        //                }
        //
        //                .content_date {
        //                    @include divSmallMarginBottom;
        //                    line-height: $minLineHeight;
        //                    border-left: 2px solid $themeColorMilk;
        //                    padding-left: 10px;
        //                }
        //            }
        //        }
        //    }
        //}
    }
}