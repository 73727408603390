.wrapper {
  height: 100px;
  width: 400px;
  color: black;
  background-color: #D9D9D9;
  padding: 15px;
  border-radius: 10px;


  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .info {
    padding: 5px;
    text-align: center;
    font-size: 20px;
    font-weight: 800;
  }


}