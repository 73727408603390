@import '../../../app/styles/variables/mixins';
@import '../../../app/styles/variables/variables';

.tableItem_box {
  @include flexBetween;
  width: 95%; // ?
  border: 2px solid $themeColorWhite;
  border-radius: $smallBorderRadius;
  margin: $defaultExtraSmallMargin;
  padding: 3px 5px;

  .tableItem_employee {
    background: #2a2a2a;
    border-radius: 5px;
    margin-right: 3px;
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    padding: 5px;
    user-select: none;
    cursor: pointer;
    
  }

  .tableItem_employee:hover {
    background: #4b4b4b;
  }

  .tableItem_title {
    word-break: break-word;
    margin-right: $defaultSmallMargin;
  }

  .tableItem_numbers {
    @include flexCenter;

    .tableItem_price {
      color: black;
      background-color: $themeColorWhite;
      border-radius: $smallBorderRadius;
      padding: 5px;
      margin-right: $defaultSmallMargin;
      min-width: 80px;
      text-align: center;

      .multiply {
        padding: 0 3px;
      }


      .tableItemQuantity {
        user-select: none;
        display: flex;
        justify-content: space-between;

        .addButton {
          border: 1px solid black;
          padding: 2px 3px 2px 2px;
          border-radius: 5px;
        }

        .addButton:hover {
          background: #4b4b4b;
          color: white;
          cursor: pointer;
        }

        .removeButton {
          border: 1px solid black;
          padding: 2px 4px 2px 4px;
          margin-right: 3px;
          border-radius: 5px;
        }

        .removeButton:hover {
          background: #4b4b4b;
          color: white;
          cursor: pointer;
        }
      }

    }

    .tableItem_sum {
      color: black;
      background-color: $themeColorWhite;
      border-radius: $smallBorderRadius;
      padding: 5px;
      min-width: 80px;
      @include flexColumn;
      gap: 5px;
    }
  }
}
