.wrapper {
  height: 300px;
  width: 500px;
  color: black;
  background-color: #7e7e7e;
  padding: 15px;
  border-radius: 10px;


  display: flex;
  flex-direction: column;
  gap: 10px;

  .label {
    font-weight: 600;
    font-size: 18px;
  }

  .text {
    flex-grow: 1;
    background-color: #343434;
    border-radius: 5px;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
  }

}
