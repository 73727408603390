@import '../../../app/styles/variables/mixins';
@import '../../../app/styles/variables/variables';

.supplyInvoiceArchiveModal_mainBlock {
    @include defaultModalStyle;
    width: 550px;

    @media screen and (max-width: 768px) {
        max-height: 500px;
        overflow-y: scroll;
    }

    @media screen and (max-width: 640px) {
        width: 80vw;
    }

    .supplyInvoiceArchiveModal_title {
        @include modalTitle;
    }

    .supplyInvoiceArchiveModal_list {
        @include divLargeMarginBottom;
        max-height: 550px;
        overflow: auto;
        padding-right: 10px;

        @media screen and (max-width: 440px){
            padding-right: 0;
        }

        @include SupplyInvoiceArchiveModal(100%);

        // @media screen and (max-width: 640px) {
        //     flex-direction: column;
        // }
    }
}

.SupplyInvoiceArchiveModal_item_contentTop {
    @media screen and (max-width: 440px) {
        font-size: 3vw;
    }
}
