.menu {
    opacity: 0;
    pointer-events: none;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: -1;
    //border-radius: 10px;
}
.overlay {
    width: 100%;
    height: 100%;
}

.test{
    position: relative;
}
.button {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 200px;
    width: 100%;
    height: 25px;
    //margin-bottom: 5px;
    background: #676767;
    border-radius: 0;
    border: 1px solid white;
}

.button:hover {
    background: #4b4b4b;
}


.content {
    visibility: hidden;
    position: absolute;
    opacity: 0;
    z-index: 10;
    //padding: 5px;
    border: 1px solid white;
    //border-radius: 12px;
    //background: rgba(0, 0, 0, 0.28);
}

.button:nth-last-child(1) {
    margin-bottom: 0;
}
.opened {
    pointer-events: auto;
    opacity: 1;
    z-index: 20;

    .content {
        opacity: 1;
        transition: 0.1s opacity cubic-bezier(.9,.53,.66,1);
        visibility: visible;
    }
}

.isClosing {
    .content {
        opacity: 0;
    }
}