@import '../../../app/styles/variables/mixins';
@import '../../../app/styles/variables/variables';

.selectProduct_mainBox {
    padding: $defaultLargeMargin;
    background-color: $themeColorGray;
    border-radius: $largeBorderRadius;
    display: flex;
    gap: $defaultLargeMargin;

    @media screen and (max-width: 1280px) {
        width: 85vw;
    }

    @media screen and (max-width: 1024px) {
        flex-direction: column;
        max-height: 600px;
        overflow-y: scroll;
    }

    .selectProduct_mainBox_leftSide {
        @include flexColumn;
        width: 32%;
        min-height: 700px;

        @media screen and (max-width: 1280px) {
            font-size: 1.2vw;
        }

        @media screen and (max-width: 1024px) {
            width: 100%;
            font-size: 2.2vw;
        }

        @media screen and (max-width: 768px) {
            font-size: 3.2vw;
        }

        > div {
            width: 100%;
        }

        .leftSide_header {
            background-color: $themeColorDark;
            border-radius: $largeBorderRadius;
            margin-bottom: $defaultSmallMargin;
            padding: $defaultSmallMargin;
            height: 150px;
        }
        .leftSide_treeView {
            background-color: $themeColorDark;
            border-radius: $largeBorderRadius;
            margin-bottom: $defaultSmallMargin;
            padding: $defaultLargeMargin;
            flex: 1 1 auto;

            @media screen and (max-width: 768px) {
                padding: 10px;
            }
        }
        .leftSide_buttons {
            width: 100%;
            @include divSmallMarginBottom;

            button {
                width: 100%;
            }
        }
    }

    .selectProduct_mainBox_rightSide {
        //border: 1px solid white;
        @include flexColumn;
        justify-content: space-between;
        width: 75%;

        @media screen and (max-width: 1024px) {
            width: 100%;
        }

        > div {
            width: 100%;
        }
        .rightSide_availableProducts {
            display: flex;
            justify-content: center;
            background-color: $themeColorDark;
            height: 300px;
            overflow: auto;
        }
        .rightSide_infoRow {
            //border: 1px solid white;
            @include flexBetween;
            height: 70px;

            @media screen and (max-width: 768px) {
                flex-direction: column;
                gap: 3vw;
                margin: 20px 0 40px 0;
            }

            .infoRow_searchField {
                width: 60%;
                padding-right: $defaultLargeMargin;
            }
            .infoRow_result {
                @include flexOnlyBetween;
                width: 40%;

                @media screen and (max-width: 768px) {
                    width: 100%;
                    gap: 2vw;
                }

                > div {
                    @include defaultFiledStyle;

                    @media screen and (max-width: 768px) {
                        padding: 6px;
                    }
                }
            }
        }
        .rightSide_chosenProducts {
            display: flex;
            justify-content: center;
            background-color: $themeColorDark;
            height: 300px;
            overflow: auto;
        }
    }
}