@import '../../../app/styles/variables/variables';

.btn {
    //z-index: 20;
    z-index: 0;
    position: relative;
    overflow: hidden;
    transition: background 400ms;
    color: var(--primary-color);
    background-color: var(--inverted-primary-color);
    padding: 5px 10px;
    font-size: 1rem;
    outline: 0;
    border: 0;
    border-radius: 0.25rem;
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.3);
    cursor: pointer;
    height: 100%; // добавил, чтобы кнопка была по высоте блока
}

.btn:disabled {
    background-color: var(--button-disabled-color, red);
}
.btn:hover {
    background-color: var(--button-hover-bgcolor);
    color: var(--button-hover-color);
}
.btn:disabled:hover {
    background-color: var(--button-disabled-color);
    cursor: inherit;
}


.waiting {
    background-color: $filterButtonWaitingColor;
}
.process {
    background-color: $filterButtonInProcessColor;
}
.ready {
    background-color: var(--button-active-bgcolor);
    color: var(--button-active-color);
}


.ended {
    background-color: green;
}
.canceled {
    background-color: $filterButtonInProcessColor;
}
.deleted {
    background-color: $filterButtonWaitingColor;
}


span.ripple {
    z-index: 20;
    position: absolute;
    border-radius: 50%;
    transform: scale(0);
    animation: ripple 600ms linear;
    opacity: 0.7;
    //background-color: var(--primary-color, #fff);
    background-color: #fff;
}

@keyframes ripple {
    to {
        transform: scale(4);
        opacity: 0;
    }
}