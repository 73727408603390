.Modal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  z-index: -100;
  color: var(--inverted-primary-color);
}

.Modal.dark {
  --bg-color: #090949;
  --primary-color: #04ff04;
}

.overlay {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.54);
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  border-radius: 12px;
  background: var(--bg-color);
  transition: 0.3s transform;
  transform: scale(0.5);
  max-width: 60%;

  @media screen and (max-width: 768px) {
    max-width: 100%;
  }
}

.opened {
  pointer-events: auto;
  opacity: 1;
  z-index: 2;

  .content {
    transform: scale(1);
  }
}

.isClosing {
  .content {
    transform: scale(0.2);
  }
}