.wrapper {
  background: white;
  display: flex;
  color: black;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;
  height: 180px;
  width: 300px;
  font-size: x-large;
  font-weight: bold;

  .number {

  }
}