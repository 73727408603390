@import '../../../../app/styles/variables/mixins';
@import '../../../../app/styles/variables/variables';

.chooseProductModal_mainBox {
  @include defaultModalStyle;
  width: 1360px;
  background-color: $themeColorDark;
  padding: 0;

  @media screen and (max-width: 1440px) {
    width: 95vw;
    overflow-y: scroll;
  }

  .chooseProductModal_wrapper {
    @include flexOnlyBetween;
    padding: $defaultLargeMargin;
    gap: 20px;
    height: 80vh;

    @media screen and (max-width: 1280px) {
      flex-direction: column;
      justify-content: flex-start;
    }

    .chooseProductModal_tagTreeView {
      background-color: $themeColorGray;
      border-radius: $smallBorderRadius;
      width: 30%;

      @media screen and (max-width: 1280px) {
        width: 100%;
      }
    }

    .chooseProductModal_catalogTable {
      width: 80%;
      @include flexColumn;
      gap: 10px;

      @media screen and (max-width: 1280px) {
        width: 100%;
      }

      .table_availableProducts {
        height: 50%;
      }

      .table_chosenProducts {
        height: 50%;
      }
    }
  }
}