.products {
  background-color: #4b4b4b;
  border-radius: 10px;
  //border: 1px white solid;
  height: 100%;
  padding: 3px;

  .select {
    display: flex;
    padding: 5px;
    gap: 5px;
    justify-content: space-between;

    .s_b {
      height: 40px;
      width: 40px;
      background-color: #0a66b7;
      border-radius: 10px;
      font-weight: 800;
      font-size: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .s_b:hover {
      cursor: pointer;
      background-color: #1c7fd7;
    }
  }

  .table {
    display: flex;
    flex-direction: column;
    gap: 5px;
    height: 86%;
    overflow: auto;
    border-radius: 10px;

    .table_row {
      display: flex;
      justify-content: space-between;
      margin: 0 5px 0 5px;
      padding: 5px;
      gap: 5px;
      background: #B1B1B1;
      border-radius: 5px;

      .table_row_right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 3px;

        .row_quant {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 5px;
          user-select: none;

          .row_quant_butt {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 15px;
            background-color: #dcdcdc;
            border-radius: 5px;

          }

          .row_quant_butt:hover {
            background-color: #c4c4c4;
            cursor: pointer;
          }
        }

        .table_row_right_data {
          text-align: center;
        }
      }
    }

    .table_row:hover {
      background-color: #939393;
    }
  }
}
