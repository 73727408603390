@import '../../../app/styles/variables/variables';
@import '../../../app/styles/variables/mixins';

// инпут
.inputWrapper {
    width: 100%;
}
.input {

}
.input:focus {

}

// дефолтные стили для инпута
.customInputDefault {
    width: 100%;
    height: 100%;
    background-color: transparent;
    border-radius: $extraSmallBorderRadius;
    padding: 10px 20px;
    font-size: 16px;
}
.customInputDefault::placeholder {
    color: white;
    opacity: 0.7;
}

// дефолтный инпут белого цвета
.customInput {
    composes: customInputDefault;
    border: 1px solid white;
    color: white;
    opacity: 0.7;
}
.customInput:focus-visible {
    border: 1px solid white;
    outline: none;
    opacity: 1;
}
.customInput:hover {
    opacity: 1;
}

// дефолтный инпут чёрного цвета
.customInputBlack {
    composes: customInputDefault;
    border: 1px solid black;
    color: black;
    opacity: 0.7;
}
.customInputBlack::placeholder {
    color: black;
    opacity: 0.7;
}
.customInputBlack:focus-visible {
    border: 1px solid black;
    outline: none;
    opacity: 1;
}
.customInputBlack:hover {
    opacity: 1;
}


// инпут c белой иконкой внутри
.searchInput {
    // вставка иконки
    composes: customInput;
    background: url("/shared/assets/workspace/magnifying-glass-white.svg") no-repeat 10px center;
    //url("/shared/assets/workspace/cancel-icon-white.svg") no-repeat center right 15px; // иконка отмены
    background-size: 25px;
    padding: 10px 20px 10px 40px;
}

// инпут c чёрной иконкой внутри
.searchInputBlack {
    // вставка иконки
    composes: customInputBlack;
    background: transparent url("/shared/assets/workspace/magnifying-glass-black.svg") no-repeat 5px center;
    background-size: 25px;
    padding: 10px 20px 10px 40px;
}

// стили для ошибок
.errorWrapper {
    padding-top: 5px;
    padding-left: 10px;
    font-size: 14px;
    color: red;
}
.errorHelperText {
    composes: errorWrapper;
}
.error {
    color: red;
    height: 20px;
}
.error:focus-visible {

}
.errorInput {
    border: 1px solid red;
    outline: none;
}
.errorInput:focus-visible {
    border: 1px solid red;
}