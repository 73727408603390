@import '../../../app/styles/variables/mixins';
@import '../../../app/styles/variables/variables';

.serviceFinalArchiveModal_mainBlock {
    @include defaultModalStyle;
    width: 1360px;

    @media screen and (max-width: 768px) {
        max-height: 500px;
        overflow-y: scroll;
        padding-top: 210px;
    }

    button {
        overflow: visible;
    }

    @media screen and (max-width: 1440px) {
        width: 80vw;

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    @media screen and (max-width: 768px) {
        width: 90vw;
        max-height: 600px;
        overflow-y: scroll;
        padding-top: 100px;
    }

    .serviceFinalArchiveModal_title {
        @include modalTitle;
    }

    .archive_wrapper {
        @include flexBetween;

        @media screen and (max-width: 1440px) {
           width: 70%;
           flex-direction: column; 
           gap: 1vw;
           margin: 1vw 0 0 0;
        }

        @media screen and (max-width: 768px) {
            width: 95%;
            flex-direction: column; 
            gap: 1vw;
            margin: 1vw 0 0 0;
         }

        > div {
            width: 430px;

            @media screen and (max-width: 1440px) {
                width: 100%;
            }

            fieldset {
                //border: 1px solid $themeColorMilk;
                border-radius: $smallBorderRadius;
            }
        }
    }

    .serviceFinalArchiveModal_list {
        @include divLargeMarginBottom;
        max-height: 550px;
        width: 400px;
        overflow: auto;
        padding-right: 10px;

        @media screen and (max-width: 1440px) {
            width: 100%;
            padding: 0.6vw;
            max-height: 450px;
        }

        @media screen and (max-width: 440px) {
            width: 100%;
        }
    }
}

