.wrapper {
  padding: 5px;

  height: 100%;
  color: black;
  font-size: 14px;
  font-weight: 400;

  .buttons {
    padding: 4px;
  }

  .table {
    border-radius: 10px;
    padding: 6px;
    background: #4b4b4b;
    height: 94%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    gap: 5px;

    .row {
      user-select: none;
      padding: 5px;
      border-radius: 5px;
      background: #7a7a7a;
      display: flex;
      justify-content: space-between;
      gap: 10px;
      height: 74px;
      flex-shrink: 0;

      .data {
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        .stat {
          white-space: nowrap;
          font-size: 18px;
          font-weight: 600;
          border-radius: 5px;
          background: #a8a8a8;
          height: 60%;
          padding: 4px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .date {
          white-space: nowrap;
          font-size: 18px;
          font-weight: 600;
        }
      }

      .users {
        overflow: hidden;
        max-width: 50%;
        display: flex;
        flex-direction: column;

        .manager {
          display: flex;
          flex-direction: column;

          .label {
            color: #2f2f2f;
            font-size: 10px;
            font-weight: 600;
          }

          .value {
            font-size: 18px;
            font-weight: 600;
            white-space: nowrap;
          }
        }

        .user {
          display: flex;
          flex-direction: column;

          .label {
            color: #4b4b4b;
            font-size: 10px;
          }

          .value {
            font-size: 16px;
            white-space: nowrap;
          }
        }
      }

      .thematic {
        display: flex;
        flex-direction: column;
        user-select: text;

        .label {
          color: #232323;
          font-size: 14px;
          font-weight: 600;
        }

        .value {
          height: 100%;
          overflow: auto;
        }
      }
    }

    .row:hover {
      background: #949494;
      cursor: pointer;
    }
  }
}

.modal {
  height: 300px;
  width: 800px;
  background: #8a8a8a;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 5px;

  .row1 {
    display: flex;
    justify-content: space-between;
    gap: 5px;

    .category {
      color: black;
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: end;
    }

    .manager {
      flex-grow: 1;
      color: black;

      span {
        font-size: 12px;
      }


    }
  }

  .row2 {
    display: flex;
    justify-content: space-between;
    gap: 5px;

    .thematic {
      width: 100%;
    }

    .save {

    }
  }

  .row3 {
    height: 200px;
  }
}

.mdl {
  border: 5px black solid;
  height: 400px;
  width: 800px;
  background: #8a8a8a;
  border-radius: 10px;

  display: flex;
  justify-content: space-between;
  gap: 5px;
  padding: 5px;
  color: black;

  .column1 {
    padding: 5px;
    height: 100%;
    //border: 1px solid white;
    width: 40%;

    display: flex;
    flex-direction: column;
    gap: 5px;

    overflow: hidden;

    .data {
      padding: 5px;
      border-radius: 10px;

      .cat {
        padding-left: 10px;
        font-size: 12px;
        font-weight: 400;
      }

      .them {
        font-size: 18px;
        font-weight: 600;
      }
    }

    .manager {
    }

    .info {
      flex-grow: 1;
      padding: 5px;
      border: 2px black solid;
      overflow: auto;
      border-radius: 5px;
    }
  }

  .column2 {
    //border: 1px solid white;
    display: flex;
    flex-direction: column;
    gap: 5px;
    overflow: hidden;
    width: 60%;

    .row1 {
      display: flex;
      gap: 5px;
      justify-content: space-between;
      align-items: end;

      .stat {
        padding: 5px;
        display: flex;
        align-items: center;
        height: 100%;
        border-radius: 5px;
        background: #3b3b3b;
      }
    }

    .table {
      background: #d9d9d9;
      width: 100%;
      height: 90%;
      border-radius: 10px;

      display: flex;
      flex-direction: column;
      gap: 5px;
      padding: 5px;

      overflow: auto;

      .inter {
        border-radius: 5px;
        background: #d9d9d9;
        width: 100%;
        padding: 5px;
        display: flex;
        gap: 10px;
        height: 72px;

        .type {
          display: flex;
          flex-direction: column;
          gap: 5px;
          width: 30px;

          .icon {
            height: 30px;
            width: 30px;
            border-radius: 5px;

          }

          .time {
            display: flex;
            flex-direction: column;
            gap: 3px;
            align-items: center;

            .time_info {
              text-align: center;
              font-weight: 600;
              font-size: 12px;
            }
          }
        }

        .data {
          overflow: auto;


          .thematic {
            font-size: 16px;
            font-weight: 600;
          }

          .core {
            font-size: 14px;
            font-weight: 500;
          }
        }
      }

      .inter:hover {
        background: #969696;

      }

      .step {
        background: #8d8d8d;
        width: 100%;
        height: 70px;
        border-radius: 10px;
        flex-shrink: 0;

        display: flex;

        .column1 {
          width: 40%;
          display: flex;
          flex-direction: column;
          gap: 1px;
          justify-content: start;

          .stat {
            border-radius: 5px;
            padding: 2px;
            background: #0a66b7;

            .label {
              font-weight: 400;
              font-size: 12px;
              color: #333333;
              text-align: center;
            }

            .value {
              text-align: center;

              font-weight: 500;
            }
          }

          .manager {

            .label {
              font-weight: 400;
              font-size: 12px;
              color: #333333;
              padding-left: 5px;
            }

            .value {
              font-weight: 500;
            }
          }
        }

        .column2 {
          max-width: 60%;
          overflow: auto;
          padding: 5px;
        }
      }

      .step:hover {
        background: #969696;
      }
    }
  }
}

.add_step {
  border: 5px black solid;
  height: 300px;
  width: 600px;
  background: #8a8a8a;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 5px;
  color: black;

  .row1 {
    display: flex;
    justify-content: space-between;
    gap: 10px;

    .manager {
      flex-grow: 1;
    }

    .status {
      flex-grow: 1;

    }
  }

  .info {
    flex-grow: 1;
  }

  .butns {
    display: flex;
    justify-content: space-between;
  }
}