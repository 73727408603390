@import '../../app/styles/variables/mixins';
@import '../../app/styles/variables/variables';

.userRoleModal_mainBox {
  @include defaultModalStyle;
  width: 700px;
  height: 750px;
  overflow-y: scroll;
  max-height: 500px;

  @media screen and (max-width: 768px) {
    width: 92vw;
  }

  > div {
    @include flexOnlyBetween;
    gap: $defaultLargeMargin;
  }

  .userRoleModal_header {
    margin-bottom: $defaultLargeMargin;
    width: 100%;
    height: 70%;
    margin-bottom: 20px;

    @media screen and (max-width: 640px) {
      height: 590px;
      flex-direction: column;
    }

    .header_groups {
      width: 30%;
      @include flexColumnOnly;

      @media screen and (max-width: 640px) {
        width: 100%;
      }

      .groups_groups {
        height: 90%;
        padding: $defaultSmallMargin;
        border-radius: $smallBorderRadius;
        background-color: $themeColorGray;
        margin-bottom: $defaultSmallMargin;

        @media screen and (max-width: 640px) {
          width: 100%;
        }

        .groups_groupsWrapper {
          @include divSmallMarginBottom;
          height: 100%;
          padding-right: 5px;
          overflow-y: auto;

          .groups_groupsItem {
            padding: $defaultSmallMargin;
            border-radius: $smallBorderRadius;
            background-color: $themeColorMilk;
            color: black;
            font-size: 20px;
            text-align: center;
          }

          .groups_groupsItem:hover {
            background-color: #808080FF;
          }

          .groups_groupsItem_selected {
            background-color: $themeColorDark;
            color: white;

            padding: $defaultSmallMargin;
            border-radius: $smallBorderRadius;
            font-size: 20px;
            text-align: center;

            @media screen and (max-width: 540px) {
              font-size: 2.6vw;
            }
          }
        }
      }

      .createGroup {
        height: 10%;
        width: 100%;

        button {
          width: 100%;
        }
      }
    }

    .header_roles {
      width: 70%;
      @include flexColumnOnly;
      gap: $defaultSmallMargin;

      @media screen and (max-width: 640px) {
        width: 100%;
      }

      .roles_description {
        height: 45%;
        padding: $defaultSmallMargin;
        border-radius: $smallBorderRadius;
        background-color: $themeColorGray;

        @media screen and (max-width: 640px) {
          width: 100%;
          max-height: 190px;
        }

        .roles_descriptionWrapper {
          @include divSmallMarginBottom;
          height: 100%;
          padding-right: 5px;
          overflow-y: auto;

          .roles_descriptionItem_selected {
            background-color: $themeColorMilk;
          }

          .roles_descriptionItem_nselected {
            background-color: #808080FF;
          }

          .roles_descriptionItem {
            padding: $defaultSmallMargin;
            border-radius: $smallBorderRadius;
            color: black;
            font-size: 20px;
            display: flex;
            justify-content: space-between;
            gap: $defaultLargeMargin;


            > div {
              word-break: break-word;
            }

            .descriptionItem_name {
              width: 80%;
            }

            .roles_descriptionItem_description {
              width: 20%;
            }

            .d_b:hover {
              background: white;
            }
          }
        }
      }

      .roles_tabs {
        height: 10%;
        @include flexBetween;
        gap: $defaultLargeMargin;

        .tabs_toggle {
          width: 85%;
          @include flexBetween;

          @media screen and (max-width: 640px) {
            gap: 2vw;
          }

          @media screen and (max-width: 540px) {
            font-size: 3vw;
          }
        }

        .tabs_arrows {
          width: 15%;
          @include flexBetween;

          img {
            width: 30px;
            height: 30px;

            &:hover {
              cursor: pointer;
            }
          }

          //> div {
          //    svg {
          //        width: 30px;
          //        height: 30px;
          //
          //        &:hover {
          //            cursor: pointer;
          //            stroke: gold;
          //        }
          //    }
          //}
        }
      }

      .roles_roles {
        height: 45%;
        padding: $defaultSmallMargin;
        border-radius: $smallBorderRadius;
        background-color: $themeColorGray;

        @media screen and (max-width: 640px) {
          max-height: 300px;
          overflow-y: hidden;
        }

        .roles_rolesWrapper {
          @include divSmallMarginBottom;
          height: 100%;
          padding-right: 5px;
          overflow-y: auto;

          .roles_rolesItem {
            padding: $defaultSmallMargin;
            border-radius: $smallBorderRadius;
            background-color: $themeColorMilk;
            color: black;
            font-size: 20px;
            text-align: center;
          }

          .roles_rolesItem_selected {
            padding: $defaultSmallMargin;
            border-radius: $smallBorderRadius;
            background-color: #808080FF;
            color: white;
            font-size: 20px;
            text-align: center;
          }
        }
      }
    }
  }

  .userRoleModal_bottom {
    width: 100%;
    height: 27%; // 3% - поправка на нижний отступ
    flex-direction: column;

    // @media screen and (max-width: 768px) {
    // }

    .bottom_deals {
      width: 70%;
      height: 100%;
      @include flexColumnOnly;
      justify-content: space-between;
      gap: $defaultSmallMargin;

      width: 100%;
      height: 300px;

      // @media screen and (max-width: 768px) {
      // }

      .deals_select {
        height: 10%; // ?
      }

      .deals_content {
        padding: $defaultSmallMargin;
        border-radius: $smallBorderRadius;
        background-color: $themeColorGray;
        height: 300px; // ?
        margin-top: 10px;

        // @media screen and (max-width: 768px) {
        //   height: 300px;
        // }

        .deals_contentWrapper {
          @include divSmallMarginBottom;
          height: 100%;
          padding-right: 5px;
          overflow-y: auto;

          .deals_contentItem {
            display: flex;
            justify-content: space-between;
            padding: $defaultSmallMargin;
            border-radius: $smallBorderRadius;
            background-color: $themeColorMilk;
            color: black;
            font-size: 20px;

            .del_but {

            }

            .del_but:hover {
              background: white;
            }
          }
        }
      }
    }

    .bottom_buttons {
      @include flexColumnOnly;
      justify-content: space-between;
      gap: 2vw;
      height: 360px;
      padding-bottom: 2vw;
      width: 100%;

      @media screen and (max-width: 768px) {
        font-size: 2.2vw;
      }

      button {
        width: 100%;
        padding-top: 14px;
        padding-bottom: 14px;
      }
    }
  }
}