.wrapper {
  padding: 10px;
  height: 300px;
  width: 700px;
  background: #4b4b4b;
  border-radius: 10px;
  display: grid;
  grid-template-areas:
        "block1 block2"
        "info info";
  grid-template-columns: 3fr 1fr;
  grid-template-rows: auto 1fr;

  gap: 10px;

  .block1 {
    grid-area: block1;


    .buf {
      display: flex;
      justify-content: space-between;

      .type {
        padding: 5px;

        .label {
          padding: 0 0 3px 10px;
          font-size: 14px;
          font-weight: 400;
          color: #ababab;
        }

        .value {
          font-size: 22px;
          font-weight: 600;
          color: #ffffff;
        }
      }

      .conf {
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
      }
    }

    .thematic {
      display: flex;
      justify-content: space-between;
      gap: 5px;
    }
  }

  .block2 {
    grid-area: block2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #d3ce80;
    border-radius: 5px;

    .label {
      padding: 0 0 3px 10px;
      font-size: 14px;
      font-weight: 400;
      color: #676767;
    }

    .value {
      font-size: 22px;
      font-weight: 600;
      color: #000000;
    }
  }

  .info {
    grid-area: info;
  }
}