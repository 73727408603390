.wrapper {
  padding: 20px;
  height: 93vh;

  .head {
    height: 8%;
    padding: 10px;
    display: flex;
    justify-content: space-around;
    gap: 20px;

    .mode {
      display: flex;
      gap: 10px;
    }

    .urgency {
      display: flex;
      gap: 10px;

    }
  }

  .body {
    height: 92%;
    display: flex;
    gap: 5px;
    width: 100%;

    .tree {
      background-color: #4b4b4b;
      padding: 5px;
      height: 96%;
      overflow-y: auto;
      overflow-x: hidden;
      flex-grow: 1;
      border-radius: 10px;
    }

    .wrap {
      height: 96%;
      width: 100%;

      .table {
        width: 100%;
        height: 100%;
        overflow: auto;
        padding: 10px;
        border: 2px black solid;
        border-radius: 25px;
      }

      .pag {
        height: 6%;
        padding: 5px;
      }
    }
  }
}

.container {
  border: 1px black solid;
  height: 100%;
  width: 100%;

  display: flex;
  gap: 10px;

  .list {
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .table {
      height: 90%;
      display: flex;
      flex-direction: column;
      gap: 5px;
      padding: 5px;
      overflow: auto;

      .row {
        background: #4b4b4b;
        border-radius: 5px;
        padding: 10px;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .cat {
          font-weight: 600;
        }

        .name {

        }
      }

      .row:hover {
        background: #262626;
        cursor: pointer;
      }
    }

    .pagination {
      height: 10%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .editor {
    width: 70%;

    .current {
      background: #4b4b4b;
      border-radius: 5px;
      padding: 10px;

      .hdr {
        display: flex;
        flex-direction: column;

        .cat {
          font-weight: 600;
        }

        .name {

        }
      }

      .gallery {

      }
    }

    .g1 {
      display: flex;
      gap: 5px;
      padding: 5px;
      width: 100%;
      overflow: auto;

      .gimg {
        height: 300px;
        width: 300px;
      }
    }
  }
}