@import '../../../app/styles/variables/mixins';
@import '../../../app/styles/variables/variables';

.stuffProfileWork_mainBlock {
    font-size: 20px;

    .stuffProfileWork_title {
        margin-bottom: $defaultExtraLargeMargin;

        .title_selectBlock {
            //display: flex;
            //align-items: center;
            @include flexBetween;
            //gap: $defaultExtraLargeMargin;
            margin-bottom: $defaultLargeMargin;
        }
        .title_calcBlock {
            @include flexBetween;

            > div:not(:last-child) {
                @include flexBetween;
                gap: $defaultSmallMargin;
            }

            .startTime {

            }
            .endTime {

            }
            .calcButton {
                button {
                    height: 41px;
                    width: 150px;
                }
            }
        }
    }
    .stuffProfileWork_hours {
        @include divSmallMarginBottom;
        margin-bottom: $defaultExtraLargeMargin;
    }
    .stuffProfileWork_salary {
        @include profileAdminSalaryFormStyles(0);
        margin-bottom: $defaultLargeMargin;
    }
    .controlBlock {
        .controlBlock_sum {
            width: 50%;
            @include flexBetween;

            .sum_input {
                width: 60%;
                margin-bottom: $defaultSmallMargin;
            }
        }
        .giveMoneyButton {
            width: 50%;

            button {
                width: 100%;
                height: 50px;
            }
        }
    }
}