@import '../../../app/styles/variables/mixins';
@import '../../../app/styles/variables/variables';

.workAct_wrapper {
  @include workActWrapperBlock;

  .workAct_mainBox {
    @include workActMainBlock;

    @include workActDateBlock;

    .workAct_title {
      line-height: $minLineHeight;

      .title_title {
        font-weight: bold;
      }

      .title_info {

      }
    }

    .client_info {
      line-height: $minLineHeight;

      .info_title {
        font-weight: bold;
      }

      .info_content {

      }
    }

    .agreement_policy {
      border-top: 1px solid black;

      padding: 10px 0;
      margin-bottom: 25px;
    }

    .shop_signature {
      :first-child {
        border-bottom: 1px solid black;
        padding-bottom: 5px;
      }

      :last-child {
        padding-top: 5px;
      }
    }

    .client_signature {
      :first-child {
        border-bottom: 1px solid black;
        padding-bottom: 5px;
      }

      :last-child {
        padding-top: 5px;
      }
    }
  }
}