.wrapper {
  width: 100%;
  height: 93vh;
  display: flex;
  gap: 10px;
  padding: 10px;
  overflow: hidden;

  .tree_container {
    width: 30%;
    height: 100%;
    padding: 10px;
    background-color: #4b4b4b;
    border-radius: 10px;
  }

  .data_container {
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    background-color: #4b4b4b;
    border-radius: 10px;

    .head {

      width: 100%;
      padding: 5px;
      display: flex;
      justify-content: space-between;
      gap: 5px;

      .drop_zone {
        flex-grow: 1;
      }
    }

    .data {
      padding: 5px;
      display: flex;
      flex-direction: column;
      gap: 5px;

      .row {
        padding: 5px;
        background-color: #262626;
        border-radius: 5px;
        height: 45px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .icon {
          height: 20px;
          width: 20px;
        }

        .name {
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .buttons {
          height: 100%;
          display: flex;
          gap: 5px;

          .base {
            background-color: #d9d9d9;
            height: 21px;
            width: 21px;
          }
        }
      }

      .row:hover {
        background-color: #2d2d2d;
        cursor: pointer;
      }
    }
  }
}