@import '../../../app/styles/variables/mixins';
@import '../../../app/styles/variables/variables';

.mainButtons {
    @include flexOnlyBetween;
    gap: $defaultLargeMargin;
    height: 60px;
    z-index: 0; // чтобы кнопка была под селектом

    button {
        width: 200px;
    }
}