.wrapper {
  display: flex;
  background: #4b4b4b;
  border-radius: 10px;
  padding: 5px;
  overflow: auto;
  gap: 5px;

  .item {
    user-select: none;
    color: black;
    font-weight: 600;
    font-size: 16px;
    padding: 5px;
    height: 60px;
    background: #777777;
    border-radius: 5px;
    //width: 80px;

    display: flex;
    flex-direction: column;

    .type {

    }

    .date {

    }

    .amount {

    }
  }

  .item:hover {
    background: #a2a2a2;
    cursor: pointer;
  }

}
