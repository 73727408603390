@import '../../app/styles/variables/mixins';
@import '../../app/styles/variables/variables';

.printModal_mainBox {
  @include defaultModalStyle;
  @include flexCenter;
  width: 80vw;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    min-width: auto;
    width: 80vw;
    max-height: 500px;
    overflow-y: scroll;
    padding-top: 600px;
  }

  @media screen and (max-width: 768px) {
    width: 95vw;
  }

  .printModal_buttons {
    width: 100%;
    display: flex;
    gap: $defaultExtraLargeMargin;
    background-color: $themeColorGray;
    padding: $defaultLargeMargin;
    border-radius: $smallBorderRadius;
    margin-bottom: $defaultSmallMargin;

    @media screen and (max-width: 768px) {
      flex-wrap: wrap;
    }

    button {
      padding: $defaultSmallMargin;
      width: 100%;
    }
  }

  .printModal_content {
    background-color: $themeColorGray;
    padding: $defaultLargeMargin;
    border-radius: $smallBorderRadius;
    display: flex;
    justify-content: center;
    width: 100%;

    @media screen and (max-width: 1024px) {
      height: 100%;
    }

    .scrollWrapper {
      padding-right: 5px;
      width: 100%;
    }
  }
}