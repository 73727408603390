.wrapper {
  padding: 5px;
  background: #a6a6a6;
  height: 90vh;
  width: 600px;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  gap: 10px;

  .data {

  }

  .inter {
    height: 30%;
  }

  .tasks {
    height: 40%;
  }
}